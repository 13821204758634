import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken} from "../_services/userService";

export const addVoting = (voting_data) => async(dispatch) =>  {

    dispatch({type:"VOTINGS_LOADING"})
    let participants = '';
    if (voting_data.not_allowed_participants) {
        for (let i = 0; i < voting_data.not_allowed_participants.length; i++) {
            participants += voting_data.not_allowed_participants[i].value+','
        }
    }

    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/voting`, {
        title: voting_data.title,
        description: voting_data.description,
        type:voting_data.type,
        is_motion:voting_data.is_motion,
        options_limit:voting_data.options_limit,
        not_allowed_participants:participants,
        required:voting_data.required,
        send_motion:voting_data.send_motion,
        agenda_item_id:voting_data.agenda_item_id,
        options:voting_data.options,
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "ADD_VOTING",
            payload:{
                voting: response.data.voting,
            }
        });

    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};

export const editVoting = (voting_data) => async (dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    let participants = '';
    if (voting_data.not_allowed_participants) {
        for (let i = 0; i < voting_data.not_allowed_participants.length; i++) {
            participants += voting_data.not_allowed_participants[i].value+','
        }
    }

    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/voting/${voting_data.id}`,{
        title: voting_data.title,
        description: voting_data.description,
        type:voting_data.type,
        is_motion:voting_data.is_motion,
        options_limit:voting_data.options_limit,
        not_allowed_participants:participants,
        required:voting_data.required,
        send_motion:voting_data.send_motion,
        agenda_item_id:voting_data.agenda_item_id,
        options:voting_data.voting_options,
    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "EDIT_VOTING",
                payload: {
                    voting: response.data.voting,
                    id: voting_data.id
                }
            })
            dispatch({type: "ALERT_SUCCESS", payload: {
                    message: "Änderung erfolgreich"
                }});
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        })
}

export const getVotingByMeeting = (meeting_id) => async(dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/voting_by_meeting/${meeting_id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
        dispatch({
            type: "GET_VOTINGS_BY_MEETING",
            payload:{
                votings: response.data.votings,
            }
        });

    }).catch(error => {
        dispatch(errorBuilder(error));
    });
}

export const startVoting = (id) => async(dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/start_voting`, {
        "voting_id":id,
        "active": 1
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
            dispatch({
                type: "START_VOTING",
                payload:{
                    voting: response.data.voting,
                    old_voting: response.data.old_voting,
                    id: id
                }
            });

            if(response.data.old_voting) {
                if(!response.data.old_voting.distinct) {
                    dispatch({type: "ALERT_INFO", payload: {
                            message: `Die Abstimmung "${response.data.old_voting.title}" wurde gestoppt, aber das Ergebnis konnte nicht im Protokoll gespeichert werden, da es nicht eindeutig ausgefallen ist.`
                        }});
                }
            }
        }).catch(error => {
            dispatch(errorBuilder(error))
        });
};


export const stopVoting = (id) => async(dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/start_voting`, {
        "voting_id":id,
        "active": 0,
        "stopped":1
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
            dispatch({
                type: "STOP_VOTING",
                payload:{
                    voting: response.data.voting,
                    id: id
                }
            });


        if(response.data.old_voting) {
            if(!response.data.old_voting.distinct) {
                dispatch({type: "ALERT_INFO", payload: {
                        message: `Die Abstimmung "${response.data.old_voting.title}" wurde gestoppt, aber das Ergebnis konnte nicht im Protokoll gespeichert werden, da es nicht eindeutig ausgefallen ist.`
                    }});
            }
        }


        }).catch(error => {
            dispatch(errorBuilder(error))
        });
};

export const pauseVoting = (id) => async(dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/pause_voting`, {
        "voting_id":id,
        "paused": 1
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "PAUSE_VOTING",
                payload:{
                    voting: response.data.voting,
                    id: id
                }
            });
        })
        .catch(error => {
            dispatch(errorBuilder(error))
        });
};

export const unpauseVoting = (id) => async(dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/pause_voting`, {
        "voting_id":id,
        "paused": 0
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "UNPAUSE_VOTING",
                payload:{
                    voting: response.data.voting,
                    old_voting: response.data.old_voting,
                    id: id
                }
            });
        })
        .catch(error => {
            dispatch(errorBuilder(error))
        });
};

export const deleteVoting = (id) => (dispatch) => {
    dispatch({type:"VOTINGS_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/voting/${id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_VOTING",
                payload: {
                    id: id
                }
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
};