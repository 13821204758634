const initState = {
    result: [],
    voting: [],
    details: [],
    not_voted_log: [],
    vote_count: 0,
    not_voted_count: 0,
    /**
     * 0 = Kein Ergebnis
     * 1 = angenommen
     * 2 = abgelehnt
     * 3 = uneindeutig
     */
    final_result_identifier: 0,
    voted_power_percent: null,
    not_voted_power_percent: null,
    isWaiting: false
};

const resultReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_RESULT":
            return {
                ...state,
                result: action.payload.result,
                voting: action.payload.voting,
                details:action.payload.details,
                not_voted_log:action.payload.not_voted_log,
                vote_count: action.payload.vote_count,
                not_voted_count: action.payload.not_voted_count,
                final_result_identifier: action.payload.final_result_identifier,
                voted_power_percent: action.payload.voted_power_percent,
                not_voted_power_percent: action.payload.not_voted_power_percent,
                isWaiting: false
            }
        case "RESULT_LOADING":
            return {
                ...state, isWaiting: true
            };
        case "RESULT_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            };
        case "CLEAR_RESULT":
            return initState;
        default:
            return state
    }
};


export default resultReducer;