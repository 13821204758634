const initState = {
    isLoggedIn: false,
    user: {},
    deputys: [],
    deputed_votes:[],
    isWaiting: false,
    resetSent: false
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state, isLoggedIn: true, user:action.payload.user, isWaiting: false
            };
        case "RESET_SENT":
            return {
                ...state, isWaiting: false, resetSent: true
            };
        case "FETCH_USER":
            return {
                ...state, isLoggedIn: true, user:action.payload.user, isWaiting: false
            };
        case "USER_DEPUTYS":
            return {
                ...state, deputys: action.payload.deputys, deputed_votes: action.payload.deputed_votes, isWaiting: false
            };
        case "USER_DEPUTYS_VOTED":
            return {
                ...state, deputed_votes: action.payload.deputed_votes, isWaiting: false
            };
        case "USER_LOADING":
            return {
                ...state, isWaiting: true
            }
        case "USER_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            }
        case "LOGOUT":
            return initState;
        default: return state;
    }

}

export default userReducer;