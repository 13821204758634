import {Card, CardBody, CardHeader, CardTitle, Container} from "reactstrap";
import {useState} from "react";
import {Redirect, useLocation} from "react-router-dom";
import {isLogin} from "../_services/userService";
import {useDispatch, useSelector} from "react-redux";
import {forgotPassword} from "../actions/userAction";
import PasswordForgot from "./Forms/PasswordForgot";

const ForgotPassword = () => {
    const [email, setEmail] = useState(null);
    const {isWaiting, resetSent} = useSelector(state => state.user);

    const dispatch = useDispatch();
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(email));
    }


    if(isLogin()) {
        if (from.pathname === "/passwort-vergessen") {
            from.pathname = "/";
        }
        return <Redirect to={from} />;
    }

    const renderContent = () => {
        if(resetSent) {
            return <p>Sofern die von dir angegebene E-Mail-Adresse im System vorhanden ist, erhälst du eine E-Mail mit den Schritten, um dein Passwort zu ändern.</p>
        }

        if(isWaiting) {
            return (
                <div className="spinner">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div>
            )
        }
        return <PasswordForgot email={email} setEmail={setEmail} submit={handleSubmit}/>
    }


    return (
        <Container fluid>
            <Card id="loginCard">
                <CardHeader><CardTitle tag="h3">Passwort Zurücksetzen</CardTitle></CardHeader>
                <CardBody>
                    {renderContent()}
                </CardBody>
            </Card>
        </Container>
    )
}

export default ForgotPassword