export const convertTime = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleTimeString("de-DE", {
    hour: "numeric",
    minute: "numeric",
  });
};

export const convertDateAndTime = (timestamp) => {
  const date = new Date(timestamp);
  const now = new Date();

  if (date.toDateString() === now.toDateString()) {
    // Date is today
    return date.toLocaleTimeString("de-DE", {
      hour: "numeric",
      minute: "numeric",
    });
  } else {
    // Date is not today
    const formattedDate = date.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    const formattedTime = date.toLocaleTimeString("de-DE", {
      hour: "numeric",
      minute: "numeric",
    });

    return `${formattedTime} Uhr (${formattedDate})`;
  }
};
