import axios from "axios";
import {getToken} from "../_services/userService";
import {errorBuilder} from "./errorAction";


export const showOrganisation = () => async (dispatch) => {
    dispatch({type:"ORGANISATION_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/organisation`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "SHOW_ORGANISATION",
                payload: {
                    organisation: response.data.organisation
                }
            })
        }).catch(error => {
            dispatch(errorBuilder(error));
        });
}