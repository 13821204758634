import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import React, {useEffect, useState} from "react";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import SelectInput from "../Inputs/SelectInput";
import {useDispatch, useSelector} from "react-redux";
import {editVoting} from "../../actions/votingAction";
import {Container} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import CheckboxInput from "../Inputs/CheckboxInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

const EditVoting = ({toggle, modal, voting}) => {
    const [voting_data, setVotingData] = useState({})

    const [option_form_data, setOptionFormData] = useState({
        label: '',
    })
    const [participant_options, setParticipantOptions] = useState([])
    const [show_form, setShowForm] = useState(false);
    const [motion_exists, setMotionExists] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const {message, type} = useSelector(state => state.alert);
    const errors = useSelector(state => state.errors);
    const {participants} = useSelector(state => state.participants);
    const {votings, isWaiting} = useSelector(state => state.votings);
    const {agenda_items} = useSelector(state => state.agenda_items);
    const {selected_meeting} = useSelector(state => state.meetings)
    const [disableFields, setDisableFields] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setVotingData(voting);
        let notAllowed = []
        if(voting.not_allowed_participants) {
            notAllowed = voting.not_allowed_participants.split(',')
        }

        let notAllowedArray = []
        let option_object = participants.map(particpant => {

            if(notAllowed.includes(particpant.id.toString())) {
                notAllowedArray.push({label: particpant.name, value: particpant.id})
            }
                return {label: particpant.name, value: particpant.id};

        })
        setVotingData(prevState => ({...prevState, not_allowed_participants:notAllowedArray}))
        setParticipantOptions(option_object);
        let selected_agenda_items = agenda_items.filter(item => item.id === voting.agenda_item_id);
        setVotingData(prevState => ({...prevState, motion: selected_agenda_items[0].motion}));
        for (let i = 0; i<votings.length; i++) {
            if(votings[i].agenda_item_id === voting.agenda_item_id) {
                if(votings[i].is_motion === 1 && votings[i].id !== voting.id) {
                        setMotionExists(true);
                        break;
                }
            }
        }

        if(voting.active || voting.stopped || voting.paused || selected_meeting.closed) {
            setDisableFields(true);
        }

        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if(submitted && !isWaiting) {
            toggle();
        }
        setSubmitted(false);
// eslint-disable-next-line
    },[isWaiting, submitted])

    const handleInputChange = (e) => {
        const {name, value} = e.target;

        if (name === "type") {
            if ((voting_data.type === "select_default" ||voting_data.type === "select" ||voting_data.type === "multi_select") && value === "text") {
                setVotingData(prevState => ({...prevState, voting_options: []}));
            }
            if (voting_data.type === "text" && (value === "multi_select" || value === "select")) {
                setVotingData(prevState => ({...prevState, voting_options: []}));
            }
            if (value === 'read_and_confirm') {
                setVotingData(prevState => ({...prevState, voting_options: []}));
            }
        }

        if (name === "required") {
            setVotingData(state => ({...state, required: !voting_data.required}))
        }
        if (name === "send_motion") {
            setVotingData(state => ({...state, send_motion: !voting_data.send_motion}))
        }
        else if (name === "is_motion") {
            if (!voting_data.is_motion === true) {
                setVotingData(state => ({
                    ...state,
                    is_motion: !voting_data.is_motion,
                    description: voting_data.motion,
                    title: "Beschlussantrag"
                }))
            } else {
                setVotingData(state => ({...state, is_motion: !voting_data.is_motion}))
            }
        } else if (name === "type" && value === "select_default") {
            setVotingData(prevState => ({...prevState, voting_options: [{label: "Ja"},{label: "Nein"},{label: "Enthaltung"}]}))
            setVotingData(prevState => ({...prevState, [name]: "select"}));
        }
        else {
            setVotingData(prevState => ({...prevState, [name]: value}));
        }

    }

    const toggleOptionForm = () => {
        setShowForm(!show_form);
    }

    const handleOptionInutchange = (e) => {
        const {name, value} = e.target;
        setOptionFormData(prevState => ({...prevState, [name]: value}))
    }

    const addOption = () => {
        const tmp_data = voting_data.voting_options.concat(option_form_data);
      setVotingData(prevState => ({...prevState, voting_options: tmp_data}))
        setOptionFormData({
            label: '',
        })
    }

    const renderOptionLimitInput = () => {
        if (voting_data.type === "multi_select") {
            return (<SelectInput
                name="options_limit"
                value={voting_data.options_limit}
                label="Maximale Anzahl der auswählbaren Optionen"
                placeholder="Maximum eingeben"
                handleInputChange={handleInputChange}
                disabled={disableFields}
                options={{
                    '0': 'unbegrenzt',
                    '1': '1',
                    '2': '2',
                    '3': '3',
                    '4': '4',
                    '5': '5',
                    '6': '6',
                    '7': '7',
                    '8': '8',
                    '9': '9',
                    '10': '10',

                }}
            />)
        }

        return null;
    }

    const deleteOption = (element_index) => {
        const tmp_data = voting_data.voting_options.filter((option, key) => key !== element_index);
        setVotingData(prevState => ({...prevState, voting_options: tmp_data}))

    }
    const renderOptionTable = () => {
        if(!voting_data.voting_options) return null
        if (voting_data.voting_options.length) {

            return voting_data.voting_options.map((item, index) => {
                return (
                    <div>
                        <span>{item.label}</span>
                        {disableFields ? null : <Button color="danger" onClick={() => deleteOption(index)}>
                            <i className="dripicons-trash"></i>
                        </Button>}
                    </div>
                )
            })
        }

    }


    const handleSubmit = () => {
        dispatch({type: "CLEAR_ERRORS"});
        dispatch({type:"ALERT_CLEAR"});
        dispatch(editVoting(voting_data));
       setSubmitted(true)
    }

    const renderFormButton = () => {

        if(disableFields) return null;

        if (voting_data.type !== '') {
            if (voting_data.type === "text" && voting_data.voting_options.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Text</strong> kann nur eine Option angegeben werden</span>
            } else if (voting_data.type === "read_and_confirm" && voting_data.voting_options.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Bestätigen</strong> kann nur eine Option angegeben werden</span>
            } else {
                if (show_form) {
                    return <Button color="danger" onClick={toggleOptionForm}>Abbrechen</Button>
                } else {
                    return <Button color="primary" onClick={toggleOptionForm}>Antwortmöglichkeit hinzufügen</Button>
                }
            }

        } else {
            return <Button color="primary" disabled={true}>Bitte Typ auswählen...</Button>
        }

    }

    const change = (e) => {
        setVotingData(prevState => ({...prevState, not_allowed_participants: e}));
    }

    const renderOptionFeedback = () => {
        let feedBack_style = {
            color: "#fa5c7c",
            fontSize: "0.75rem",
            fontWeight: "400"

        }

        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors["options"]) {
                return (
                    <div>
                        <p style={feedBack_style}>Bitte geben Sie Antwortmöglichkeiten an.</p>
                    </div>
                )
            }
        }
    }

    const renderForm = () => {
        if (show_form) {
            if (voting_data.type === 'select' || voting_data.type === 'multi_select') {

                return (
                    <>
                        <p>Antwortmöglichkeit</p>
                        <div>
                            <TextInput
                                placeholder="Antwortmöglichkeit eingeben"
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                                disabled={disableFields}
                            />
                            <Button onClick={addOption} color="primary"><i
                                className="dripicons-checkmark"></i></Button>
                        </div>
                    </>
                )

            } else if (voting_data.type === 'read_and_confirm') {
                if (voting_data.voting_options.length <= 0) {
                    return (
                        <div>
                            <TextAreaInput
                                placeholder="Text eingeben"
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                                rows={3}
                                cols={50}
                                disabled={disableFields}
                            />
                            <Button className="textarea-button" onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            } else {
                if (voting_data.voting_options.length <= 0) {
                    return (
                        <div>
                            <TextInput
                                placeholder="Label eingeben"
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                                disabled={disableFields}
                            />
                            <Button onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            }
        }
    }



    return (
        <Modal size="lg" id="addVotingModal" isOpen={modal} backdrop="static" toggle={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>Abstimmung bearbeiten</ModalHeader>
            <ModalBody>
                {message &&
                <Container fluid>
                    <Row>
                        <div className={`${type} alert-dismissible fade show`} role="alert">
                            <p>{message}</p>
                            <button type="button" className="btn-close" aria-label="Close"
                                    data-bs-dismiss="alert"
                                    onClick={() => dispatch({type: "ALERT_CLEAR"})}><i
                                className="mdi mdi-close"/></button>
                        </div>
                    </Row>
                </Container>
                }
                <Row>
                    <Col>
                        <div className="beschlussantrag-infobox">
                            {!motion_exists ? <CheckboxInput
                               name="is_motion"
                               label="Ist diese Abstimmung ein Beschlussantrag?"
                               handleInputChange={handleInputChange}
                               value=""
                               checked={voting_data.is_motion}
                               disabled={disableFields}
                            /> : <p><strong>Beachten Sie:</strong><br/>Es wurde bereits ein Beschlussantrag für diesen TOP angelegt.</p>}
                            {!motion_exists ? <p>Ist der Haken angewählt, wird der Beschlussvorschlag in die Beschreibung übernommen (Änderung ist möglich)</p> : ""}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{display:'flex'}}>
                     <CheckboxInput
                            name="send_motion"
                            label="Beschreibung der Abstimmung an App senden?"
                            handleInputChange={handleInputChange}
                            value=""
                            checked={voting_data.send_motion}
                            disabled={disableFields}
                        />
                        <FontAwesomeIcon data-tip data-for="sendToAppTip" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="sendToAppTip" place="top" effect="solid">
                            Ist der Haken angewählt, können die Teilnehmer die Beschreibung der Abstimmung in der Abstimmungs-App sehen.
                        </ReactTooltip>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="d-inline-block mb-1" >Abstimmungstitel*</p>
                        <FontAwesomeIcon data-tip data-for="registerTip" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="registerTip" place="top" effect="solid">
                            Hier sollte die Abstimmung kurz und knapp beschrieben werden.
                        </ReactTooltip>
                        <TextInput value={voting_data.title} name="title" handleInputChange={handleInputChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="d-inline-block mb-1" >Beschreibung</p>
                        <FontAwesomeIcon data-tip data-for="description" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="description" place="top" effect="solid">
                            Hier kann der Gegenstand der Abstimmung beschrieben werden.
                        </ReactTooltip>
                        <TextAreaInput
                            name="description"
                            value={voting_data.description}
                            placeholder="Beschreibung eingeben"
                            disabled={disableFields}
                            handleInputChange={handleInputChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="d-inline-block mb-1" >Art der Abstimmung*</p>
                        <FontAwesomeIcon data-tip data-for='choices' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="choices" place="top" effect="solid">
                            Hier können die Auswahlmöglichkeiten für Teilnehmer eingestellt werden.
                        </ReactTooltip>
                        <div className="no-label">
                        <SelectInput
                            name="type"
                            value={voting_data.type}
                            placeholder="Art der Abstimmung auswählen"
                            handleInputChange={handleInputChange}
                            disabled={disableFields}
                            options={{
                                'select_default': 'Einfache Auswahl (Ja / Nein / Enthaltung)',
                                'select': 'Einfache Auswahl (Eigene Optionen)',
                                'multi_select': 'Mehrfach Auswahl',
                                'read_and_confirm': 'Bestätigung durch Teilnehmer',
                                'text': 'Texteingabe durch Teilnehmer',
                            }}
                        />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <p className="d-inline-block mb-1" >Ausgeschlossene Teilnehmer</p>
                            <FontAwesomeIcon id="not_allowed" data-tip data-for="not_allowed" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="not_allowed" place="top" effect="solid">
                                Hier können einzelne Teilnehmer von der Abstimmung ausgeschlossen werden.
                            </ReactTooltip>
                            <MultiSelect
                                options={participant_options}
                                value={voting_data.not_allowed_participants}
                                onChange={change}
                                labelledBy={"Select"}
                                className="multiSelectDropdown"
                                disabled={disableFields}
                                overrideStrings={{
                                    "allItemsAreSelected": "Alle Teilnehmer wurden ausgewählt.",
                                    "clearSearch": "Suche leeren",
                                    "noOptions": "Keine Teilnehmer verfügbar",
                                    "search": "Suchen",
                                    "selectAll": "Alle auswählen",
                                    "selectSomeItems": "Auswählen"
                                }}
                            />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {renderOptionLimitInput()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {renderFormButton()}
                    </Col>
                </Row>
                <Row>
                    <Col id="voting_options">
                        {renderForm()}
                        {renderOptionTable()}
                        {renderOptionFeedback()}
                    </Col>
                </Row>

                <div className="processingButtons">
                    <Button color="lightgray" onClick={toggle}>Abbrechen</Button>
                    <Button color="primary" onClick={() => handleSubmit()}>Speichern</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default EditVoting;