const initState = {
    votings: [],
    selected_voting: null,
    isWaiting: false
};

const votingReducer = (state = initState, action) => {
    switch (action.type) {
        /*case "DELETE_VOTING":
            return {...state, votings:state.agenda_items.filter((item) => item.id !== action.payload.id), isWaiting: false};*/
        case "ADD_VOTING":
            return {
                ...state, votings: state.votings.concat(action.payload.voting),isWaiting: false
            };
        case "EDIT_VOTING":
            let edited_voting = state.votings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.voting
                }
                return item
            });

            return {...state, votings: edited_voting,isWaiting: false}
        case "GET_VOTINGS_BY_MEETING":
            return {
                ...state, votings: action.payload.votings, isWaiting: false
            }
        case "DELETE_VOTING":
            const filtered_votings =  state.votings.filter((item) => item.id !== action.payload.id);
            return {...state, votings: filtered_votings,isWaiting: false};
        case "START_VOTING":
            const newState = state.votings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.voting
                }
                if (action.payload.old_voting) {
                    if (item.id === action.payload.old_voting.id) {
                        return action.payload.old_voting
                    }
                }
                return item
            })
            return {...state, votings: newState, isWaiting: false};
        case "STOP_VOTING":
            const voting_state = state.votings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.voting
                }
                return item
            })
            return {...state, votings: voting_state, isWaiting: false};
        case "PAUSE_VOTING":
            const newState2 = state.votings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.voting
                }
                return item
            })
            return {...state, votings: newState2, isWaiting: false};
            case "UNPAUSE_VOTING":
            const newState3 = state.votings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.voting
                }
                if (action.payload.old_voting) {
                    if (item.id === action.payload.old_voting.id) {
                        return action.payload.old_voting
                    }
                }
                return item
            })
            return {...state, votings: newState3, isWaiting: false};
        case "VOTINGS_LOADING":
            return {
                ...state, isWaiting: true
            };

        case "SELECT_VOTING":
            return {
                ...state, selected_voting: action.payload.voting
            }
        case "VOTINGS_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            };
        case "CLEAR_VOTINGS":
            return initState;
        default:
            return state
    }
};


export default votingReducer;