const initState = {
  agenda_items: [],
  selected_agenda_item: null,
  isWaiting: false,
};

const agendaItemReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_AGENDA_ITEMS":
      return {
        ...state,
        agenda_items: action.payload.agenda_items,
        isWaiting: false,
      };
    case "DELETE_AGENDA_ITEM":
      return {
        ...state,
        agenda_items: state.agenda_items.filter(
          (item) => item.id !== action.payload.id
        ),
        isWaiting: false,
      };
    case "ADD_AGENDA_ITEM":
      return {
        ...state,
        agenda_items: action.payload.agenda_items,
        isWaiting: false,
      };
    case "EDIT_AGENDA_ITEM":
      return {
        ...state,
        agenda_items: action.payload.agenda_items,
        isWaiting: false,
      };
    case "DELETE_AGENDA_ITEM_FILE":
      let new_state = state.agenda_items.map((item) => {
        if (item.id === action.payload.agenda_item.id) {
          return action.payload.agenda_item;
        }
        return item;
      });
      return { ...state, agenda_items: new_state, isWaiting: false };
    case "AGENDA_ITEMS_LOADING":
      return {
        ...state,
        isWaiting: true,
      };
    case "AGENDA_ITEMS_LOADING_ABORT":
      return {
        ...state,
        isWaiting: false,
      };
    case "CLEAR_AGENDA_ITEMS":
      return initState;
    default:
      return state;
  }
};

export default agendaItemReducer;
