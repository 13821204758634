import React from "react";
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch } from "react-redux";
import {
  deleteParticipant,
  forceParticipantLogout,
} from "../../../actions/participantAction";

export default function EditBtn({
  meeting,
  editMode,
  editFormData,
  participant,
  enableEdit,
  handleEditSubmit,
  activateEditMode,
  deactivateEditMode,
}) {
  const dispatch = useDispatch();

  const handleDelete = (participant) => {
    dispatch(deleteParticipant(participant?.id));
  };

  const participantLogout = (participantId) => {
    dispatch(forceParticipantLogout(participantId));
  };

  const renderEditSubmitButton = () => {
    if (enableEdit) {
      return (
        <Button color="link" onClick={() => handleEditSubmit()}>
          <i className="dripicons-checkmark"></i>
        </Button>
      );
    } else {
      return (
        <Button color="link" disabled={true} onClick={() => handleEditSubmit()}>
          <i className="dripicons-checkmark"></i>
        </Button>
      );
    }
  };

  if (meeting?.closed) {
    return "";
  }
  if (editMode && editFormData?.id === participant?.id) {
    return (
      <>
        {renderEditSubmitButton()}
        <Button color="link" onClick={() => deactivateEditMode()}>
          <i className="dripicons-cross"></i>
        </Button>
      </>
    );
  } else {
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle caret color="primary">
          Aktion
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => activateEditMode(participant)}>
            Bearbeiten
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              if (
                window.confirm(
                  "Sind Sie sicher, dass sie das Element löschen wollen?"
                )
              )
                handleDelete(participant);
            }}
          >
            Löschen
          </DropdownItem>
          {participant?.app_login ? (
            <DropdownItem onClick={() => participantLogout(participant.id)}>
              Ausloggen
            </DropdownItem>
          ) : (
            ""
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
}
