import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Card } from "react-bootstrap";
import { Button } from "reactstrap";
import {
  closeMeeting,
  startMeeting,
  exportDocProtocol,
  exportPdfProtocol,
  exportInvite,
} from "../../actions/meetingAction";
import AgendaItem from "../AgendaItem";
import AgendaItemTemplateImport from "../Modals/AgendaItemTemplateImport";
import { convertTime, convertDateAndTime } from "../../_utils/datetime";

export default function MeetingData({
  meeting,
  participants,
  quorum,
  toggleParticipantForm,
  weg,
  toggle,
  toggleAgendaItemTemplateImportModal,
  votingTemplateImportModal,
  agenda_items,
  deputyVote_enabled,
  wating_for_agenda_items,
  toggelDeputyVote,
  toggleVotingForm,
  toggleShowResult,
  editButtonHandler,
  setVotingFormData,
  setTriggerReload,
}) {
  const dispatch = useDispatch();

  const handleStartMeeting = () => {
    const confirmText =
      "Sind Sie sicher, dass sie die Versammlung starten wollen?";
    if (window.confirm(confirmText)) {
      dispatch({ type: "ALERT_CLEAR" });
      dispatch(startMeeting(meeting?.id));
    }
  };

  const handleCloseMeeting = () => {
    const confirmText =
      "Sind Sie sicher, dass sie die Versammlung schließen " +
      "wollen? Es wird keine Teilnahme oder Bearbeitung an" +
      " dieser Versammlung mehr möglich sein.";
    if (window.confirm(confirmText)) {
      dispatch({ type: "ALERT_CLEAR" });
      dispatch(closeMeeting(meeting?.id));
    }
  };

  const renderQuorumPerDivider = () => {
    if (!quorum) return null;

    let dingens = [];
    for (const [key, value] of Object.entries(quorum)) {
      dingens.push(
        <span key={key}>
          <strong>{key}</strong>
          <br />
          {value["absolut"]} ({value["percent"]}%)
        </span>
      );
    }
    return dingens;
  };

  const downloadProtocolButtonHandler = () => {
    dispatch(exportPdfProtocol(meeting.id));
  };

  const downloadDocProtocolButtonHandler = () => {
    dispatch(exportDocProtocol(meeting.id));
  };

  const downloadInviteButtonHandler = (type) => {
    dispatch(exportInvite(meeting.id, type));
  };

  const renderAgendaItems = () => {
    if (agenda_items.length) {
      return agenda_items.map((item, key) => (
        <Row key={key} className="agendaItem">
          <div style={{ width: "100%" }}>
            <AgendaItem
              meeting={meeting}
              deputyVote_enabled={deputyVote_enabled}
              key={item.id}
              item={item}
              index={key}
              toggleDeputy={toggelDeputyVote}
              toggleVotingModal={toggleVotingForm}
              toggleShowResult={toggleShowResult}
              editButtonHandler={editButtonHandler}
              setFormData={setVotingFormData}
            />
          </div>
        </Row>
      ));
    } else {
      if (wating_for_agenda_items) {
        return (
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        );
      } else {
        return "Es wurden noch keine Tagesordnungspunkte angelegt";
      }
    }
  };

  return (
    <>
      <Row className="headRow">
        <div>
          <Link to={`/weg/${meeting.weg_id}`}>
            <i className="dripicons-arrow-thin-left" />
          </Link>
          <h1>{meeting.title}</h1>
          <span className="attendingParticipants">
            {participants?.length === 0
              ? "Noch keine Teilnehmer"
              : "Gesamtzahl aller möglichen Teilnehmer: " + participants.length}
          </span>
        </div>
        <div className="multipleButtons topButton">
          {!meeting.meeting_start && (
            <Button color="danger" onClick={handleStartMeeting}>
              Versammlung starten
            </Button>
          )}
          {meeting.meeting_start && !meeting.closed && (
            <Button color="danger" onClick={handleCloseMeeting}>
              Versammlung schließen
            </Button>
          )}
          <Button color="success" onClick={toggleParticipantForm}>
            <i
              className="mdi mdi-account-group-outline"
              style={{ marginRight: 5 }}
            />
            Teilnehmerliste
          </Button>
        </div>
      </Row>
      <Row className="meetingOverviewTile">
        <Card>
          <Card.Body>
            <Card.Title>{meeting.title}</Card.Title>
            {meeting?.meeting_start && !meeting?.closed && (
              <Card.Subtitle>
                <span style={{ color: "green" }}>
                  Versammlung läuf seit{" "}
                  {convertDateAndTime(meeting?.meeting_start)} Uhr
                </span>
              </Card.Subtitle>
            )}
            {meeting?.closed && (
              <Card.Subtitle>
                Versammlung wurde um {convertTime(meeting?.meeting_start)} Uhr
                beendet.
              </Card.Subtitle>
            )}

            <Card.Text style={{ whiteSpace: "pre-wrap" }}>
              {meeting.comment}
              <div>
                <span>
                  <strong>Datum der Versammlung</strong>
                  <br />
                  {meeting.date}
                </span>
                <span>
                  <strong>Uhrzeit der Versammlung</strong>
                  <br />
                  {meeting.time}
                </span>
                <span>
                  <strong>Protokollführung/Versammlungsleitung</strong>
                  <br />
                  {meeting.meeting_leader
                    ? meeting.meeting_leader
                    : meeting.user
                    ? meeting.user.name
                    : ""}
                </span>
                <span>
                  <strong>Ort</strong>
                  <br />
                  {meeting.place}
                </span>
                <span>
                  <strong>Versammlungsart</strong>
                  <br />
                  {meeting.meeting_type}
                </span>
                <span>
                  <strong>Von der Verwaltung anwesend</strong>
                  <br />
                  {meeting.administration}
                </span>
                <span>
                  <strong>Teilnehmer</strong>
                  <br />
                  {participants.length}
                </span>
                <span>
                  <strong>Stimmrecht nach</strong>
                  <br />
                  {weg?.suffrage}
                </span>
                <span>
                  <strong>Vollmachtserteilung erlaubt an</strong>
                  <br />
                  {weg?.power_of_proxy}
                </span>
              </div>
              <div>{renderQuorumPerDivider()}</div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      <Row>
        <div className={"top-head"}>
          <h2 style={{ marginTop: 30 }}>Tagesordnungspunkte</h2>
          <div className="topButtons">
            {meeting.closed ? (
              <>
                <Button
                  className={"mr-2"}
                  color="success"
                  onClick={downloadProtocolButtonHandler}
                >
                  <i className="mdi mdi-export" style={{ marginRight: 5 }} />{" "}
                  PDF Protokoll exportieren
                </Button>
                <Button
                  color="success"
                  onClick={downloadDocProtocolButtonHandler}
                >
                  <i className="mdi mdi-export" style={{ marginRight: 5 }} />
                  Word Protokoll exportieren
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={"mr-2"}
                  color="danger"
                  onClick={() => downloadInviteButtonHandler("doc")}
                >
                  <i
                    className="mdi mdi-file-outline"
                    style={{ marginRight: 5 }}
                  />
                  Word-Einladung exportieren
                </Button>
                <Button
                  className={"mr-2"}
                  color="danger"
                  onClick={() => downloadInviteButtonHandler("pdf")}
                >
                  <i
                    className="mdi mdi-file-outline"
                    style={{ marginRight: 5 }}
                  />
                  PDF-Einladung exportieren
                </Button>
                <Button className={"mr-2"} color="success" onClick={toggle}>
                  <i
                    className="mdi mdi-file-outline"
                    style={{ marginRight: 5 }}
                  />
                  Tagesordnungspunkt anlegen
                </Button>
                <Button
                  color="success"
                  onClick={toggleAgendaItemTemplateImportModal}
                >
                  Tagesordnungspunkt aus Vorlage hinzufügen
                </Button>
                {votingTemplateImportModal && (
                  <AgendaItemTemplateImport
                    forceReload={setTriggerReload}
                    modal={votingTemplateImportModal}
                    toggle={toggleAgendaItemTemplateImportModal}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Row>
      {renderAgendaItems()}
    </>
  );
}
