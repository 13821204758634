import React from "react";
import { Button } from "reactstrap";

export default function ImportParticipantBtn({ meeting, toggleUpload }) {
  if (!meeting || meeting.closed) return null;
  if (
    meeting &&
    Object.keys(meeting).length === 0 &&
    meeting.constructor === Object
  )
    return null;

  return <Button onClick={toggleUpload}>Teilnehmerliste importieren</Button>;
}
