import axios from "axios";
import { errorBuilder } from "./errorAction";
import { getToken, isLogin, logout } from "../_services/userService";
import { history } from "../_helpers";

export const login = (username, password) => async (dispatch) => {
  dispatch({ type: "USER_LOADING" });
  await axios
    .post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/login`,
      {
        email: username,
        password: password,
      },
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", JSON.stringify(response.data.access_token));
      dispatch({ type: "ALERT_CLEAR" });
      dispatch({
        type: "LOGIN",
        payload: {
          user: response.data.user,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: "USER_LOADING" });
  dispatch({ type: "CLEAR_ERRORS" });
  await axios
    .post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/forgot-password`,
      {
        email: email,
      },
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({ type: "RESET_SENT" });
    })
    .catch((error) => {
      dispatch({ type: "USER_LOADING_ABORT" });
      dispatch(errorBuilder(error));
    });
};

export const resetPassword = (data) => async (dispatch) => {
  dispatch({ type: "USER_LOADING" });
  dispatch({ type: "CLEAR_ERRORS" });
  await axios
    .post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/reset-password`,
      {
        token: data.token,
        email: data.email,
        password: data.password,
        password_confirmation: data.passwordConfirm,
      },
      {
        headers: {
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({ type: "USER_LOADING_ABORT" });
      dispatch({
        type: "ALERT_INFO",
        payload: {
          message: "Dein Passwort wurde erfolgreich geändert.",
        },
      });
      history.push("/login");
    })
    .catch((error) => {
      dispatch({ type: "USER_LOADING_ABORT" });
      dispatch(errorBuilder(error));
    });
};

export const logoutAction = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_AUTH_ENDPOINT}/logout`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      logout();
      dispatch({ type: "LOGOUT" });
      history.push("/login");
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const getUserDeputys = (meeting_id) => async (dispatch) => {
  dispatch({ type: "USER_LOADING" });
  await axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/get_user_deputys/${meeting_id}`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "USER_DEPUTYS",
        payload: {
          deputys: response.data.deputys,
          deputed_votes: response.data.votes,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const getUser = () => async (dispatch) => {
  dispatch({ type: "USER_LOADING" });
  if (!isLogin()) {
    dispatch({ type: "USER_LOADING_ABORT" });
  }

  await axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/user`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "FETCH_USER",
        payload: {
          user: response.data.user,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};
