import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showWeg } from "../actions/wegAction";
import {
  addMeeting,
  deleteMeeting,
  editMeeting,
  getMeetings,
} from "../actions/meetingAction";
import AddMeeting from "./Forms/AddMeeting";
import MeetingsTable from "./Meeting/MeetingsTable";

const meetingDataInitState = {
  title: "",
  meeting_type: "",
  invitation_date: "",
  date: "",
  time: "",
  place: "",
  administration: "",
  secretary: "",
  comment: "",
  divisors: "",
  meeting_id: "",
  meeting_leader: "",
  divider_number: "",
};

export default function MeetingList(props) {
  const dispatch = useDispatch();
  const weg = useSelector((state) => state.wegs.selected_weg);
  const { meetings, isWaiting } = useSelector((state) => state.meetings);
  const [modal, setModal] = useState(false);
  const [meetingData, setMeetingData] = useState(meetingDataInitState);

  useEffect(() => {
    dispatch(showWeg(props.match.params.wegId));
    dispatch(getMeetings(props.match.params.wegId));
    return function cleanup() {
      dispatch({ type: "CLEAR_ERRORS" });
      dispatch({ type: "CLEAR_MEETINGS" });
    };
  }, [dispatch, props.match.params.wegId]);

  useEffect(() => {
    if (modal && !isWaiting) {
      setModal(false);
    } // eslint-disable-next-line
  }, [isWaiting]);

  const toggle = () => setModal(!modal);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setMeetingData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "CLEAR_ERRORS" });
    if (meetingData.meeting_id) {
      dispatch(editMeeting(meetingData));
    } else {
      dispatch(addMeeting(meetingData, weg.id));
    }
  };

  const handleClose = () => {
    setMeetingData(meetingDataInitState);
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({ type: "MEETING_LOADING_ABORT" });
  };

  const deleteButtonHandler = (e) => {
    dispatch(deleteMeeting(e));
  };

  const editButtonHandler = (id) => {
    let meeting = meetings.filter((item) => item.id === id);
    meeting = meeting[0];
    setMeetingData({
      meeting_id: id,
      title: meeting.title,
      administration: meeting.administration,
      meeting_type: meeting.meeting_type,
      invitation_date: meeting.invitation_date,
      date: meeting.date,
      time: meeting.time,
      place: meeting.place,
      secretary: meeting.secretary,
      comment: meeting.comment,
      divisors: meeting.divisors,
      weg_id: meeting.weg_id,
      meeting_leader: meeting.meeting_leader,
    });
    toggle();
  };

  const renderMeetingModal = () => {
    if (modal) {
      return (
        <AddMeeting
          onClosed={handleClose}
          modal={modal}
          toggle={toggle}
          handleInputChange={inputChangeHandler}
          handleSubmit={handleSubmit}
          values={meetingData}
        />
      );
    }
    return null;
  };

  return (
    <Container fluid>
      <Row className="headRow">
        <div>
          <Link to={"/"}>
            <i className="dripicons-arrow-thin-left"></i>
          </Link>
          <h1>{weg.name}</h1>
        </div>
        <div className="topButton">
          <Button color="success" onClick={toggle}>
            Versammlung anlegen
          </Button>
          {renderMeetingModal()}
        </div>
      </Row>
      <Row className="overviewTable">
        <Col>
          <h2>Versamm&shy;lungen</h2>
        </Col>

        <Col>
          <MeetingsTable
            meetings={meetings}
            isWaiting={isWaiting}
            deleteButtonHandler={deleteButtonHandler}
            editButtonHandler={editButtonHandler}
          />
        </Col>
      </Row>
    </Container>
  );
};

