import {Card, CardBody, CardHeader, CardTitle, Container} from "reactstrap";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PasswordResetForm from "./Forms/PasswordReset";
import {resetPassword} from "../actions/userAction";
import {Redirect, useLocation} from "react-router-dom";
import {isLogin} from "../_services/userService";

const ResetPassword = (props) => {

    const dispatch = useDispatch();
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };

    const {isWaiting} = useSelector(state => state.user)

    const [password, setPassword] = useState(null)
    const [passwordConfirm, setPasswordConfirm] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(resetPassword({token:props.match.params.token,email:props.match.params.email,password:password,passwordConfirm:passwordConfirm}));
    }

    if(isLogin()) {
        if (from.pathname === "/password-reset") {
            from.pathname = "/";
        }
        return <Redirect to={from} />;
    }

    if(props.match.params.token === undefined || props.match.params.email === undefined) {
        if (from.pathname === "/password-reset") {
            from.pathname = "/";
        }
        return <Redirect to={from} />;

    }

    const renderContent = () => {
        if(isWaiting) {
            return (
                <div className="spinner">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div>
            )
        }
        return <PasswordResetForm password={password} setPassword={setPassword} passwordConfirm={passwordConfirm} setPasswordConfirm={setPasswordConfirm} submit={handleSubmit}/>
    }


    return (
        <Container  id="loginCard" fluid>
            <Card>
                <CardHeader><CardTitle tag="h3">Änderung deines Passwort</CardTitle></CardHeader>
                <CardBody>
                    {renderContent()}
                </CardBody>
            </Card>
        </Container>
    )
}

export default ResetPassword;