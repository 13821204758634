import React, { useEffect } from "react";
import Topbar from "./components/Topbar";
import { Router } from "react-router-dom";

import Footer from "./components/Footer";
import Routing from "./components/Routing";

import "./assets/scss/Saas.scss";
import { history } from "./_helpers";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./actions/userAction";
import { Container, Row } from "react-bootstrap";
import { isLogin } from "./_services/userService";

const App = () => {
  const { message, type } = useSelector((state) => state.alert);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user &&
      Object.keys(user).length === 0 &&
      user.constructor === Object &&
      isLogin()
    ) {
      dispatch(getUser());
    } // eslint-disable-next-line
  }, [user]);

  return (
    <div className="app">
      <div className="wrapper">
        <div className="content-page">
          <div className="content">
            <Router history={history}>
              <Topbar />
              {message && (
                <Container fluid>
                  <Row>
                    <div
                      className={`${type} alert-dismissible fade show`}
                      role="alert"
                    >
                      <p>{message}</p>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        data-bs-dismiss="alert"
                        onClick={() => dispatch({ type: "ALERT_CLEAR" })}
                      >
                        <i className="mdi mdi-close" />
                      </button>
                    </div>
                  </Row>
                </Container>
              )}
              <Routing />
              <Footer />
            </Router>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
