import Emailnput from "./Inputs/EmailInput";
import PasswordInput from "./Inputs/PasswordInput";
import {useState} from "react";
import {Container, Card, CardBody, CardTitle, CardHeader} from "reactstrap";
import {useDispatch} from "react-redux";
import {login} from "../actions/userAction";
import {isLogin} from "../_services/userService";
import {Link, Redirect, useLocation} from "react-router-dom";

const Login = (props) => {

    const dispatch = useDispatch();
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    })

    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };

    const inputChangeHandler = (e) => {
        const {name, value} = e.target;
        setCredentials(prevState => ({...prevState, [name]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(credentials.email, credentials.password))
    }

    if(isLogin()) {
        if (from.pathname === "/login") {
           from.pathname = "/";
        }
        return <Redirect to={from} />;
    }


    return (

        <Container fluid>
            <Card id="loginCard">
                <CardHeader><CardTitle tag="h3">Login</CardTitle></CardHeader>
                <CardBody>
                    <form>
                        <Emailnput
                            placeholder="E-Mail-Adresse eingeben"
                            name="email"
                            label="E-Mail-Adresse"
                            handleInputChange={inputChangeHandler}
                        />
                        <PasswordInput
                            placeholder="Passwort"
                            name="password"
                            label="Passwort"
                            handleInputChange={inputChangeHandler}
                        />
                        <div className={'mb-2'}>
                            <Link to={"/passwort-vergessen"}>Passwort vergessen?</Link>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >Login
                        </button>
                    </form>


                </CardBody>
            </Card>

        </Container>
    )

}

export default Login;