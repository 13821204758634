import axios from "axios";
import fileDownload from "js-file-download";
import { errorBuilder } from "./errorAction";
import { getToken, getUser } from "../_services/userService";

export const getMeetings = (weg_id) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  await axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/weg_meetings/${weg_id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "FETCH_MEETINGS",
        payload: {
          meetings: response.data.meetings,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const showMeeting = (id) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  await axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "SHOW_MEETING",
        payload: {
          meeting: response.data.meeting,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const deleteMeeting = (id) => (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "DELETE_MEETING",
        payload: {
          id: id,
        },
      });
      dispatch({
        type: "ALERT_SUCCESS",
        payload: {
          message: "Erfolgreich gelöscht.",
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
      dispatch({
        type: "ALERT_ERROR",
        payload: {
          message: "Fehler beim Löschen.",
        },
      });
    });
};

export const addMeeting = (meetingData, wegId) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  let user = getUser();
  await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/meeting`,
      {
        title: meetingData.title,
        meeting_type: meetingData.meeting_type,
        date: meetingData.date,
        invitation_date: meetingData.invitation_date,
        time: meetingData.time,
        user_id: user.id,
        meeting_leader: meetingData.meeting_leader,
        administration: meetingData.administration,
        comment: meetingData.comment,
        place: meetingData.place,
        divisors: meetingData.divisors,
        weg_id: wegId,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "ADD_MEETING",
        payload: {
          meeting: response.data.meeting,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const closeMeeting = (meeting_id) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  let today = new Date();
  let date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date + " " + time;
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}`,
      {
        closed: dateTime,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "CLOSE_MEETING",
        payload: {
          meeting: response.data.meeting,
          id: meeting_id,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const startMeeting = (meeting_id) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  let today = new Date();
  let date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  let time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date + " " + time;
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}`,
      {
        meeting_start: dateTime,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "START_MEETING",
        payload: {
          meeting: response.data.meeting,
          id: meeting_id,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const exportProtocol = (meeting_id, docType) => async (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/export_protocol/${meeting_id}`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((res) => {
      let date = new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      });
      fileDownload(
        res.data,
        `${date}_Protokoll-Versammlung${meeting_id}.${docType}`
      );
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const exportPdfProtocol = (meeting_id) => async (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/export_protocol/${meeting_id}`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((res) => {
      let date = new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      });
      fileDownload(
        res.data,
        date + "_Protokoll-Versammlung" + meeting_id + ".pdf"
      );
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const exportDocProtocol = (meeting_id) => async (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/export_doc_protocol/${meeting_id}`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((res) => {
      let date = new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      });
      fileDownload(
        res.data,
        date + "_Protokoll-Versammlung" + meeting_id + ".docx"
      );
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const exportInvite = (meeting_id, type) => async (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/invite`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: { type: type },
    })
    .then((res) => {
      let date = new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      });

      if (type === "doc") {
        fileDownload(
          res.data,
          date + "_Einladung-Versammlung" + meeting_id + ".docx"
        );
      } else if (type === "pdf") {
        fileDownload(
          res.data,
          date + "_Einladung-Versammlung" + meeting_id + ".pdf"
        );
      }
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const editMeeting = (meetingData) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/meeting/${meetingData.meeting_id}`,
      {
        title: meetingData.title,
        meeting_type: meetingData.meeting_type,
        invitation_date: meetingData.invitation_date,
        date: meetingData.date,
        time: meetingData.time,
        meeting_leader: meetingData.meeting_leader,
        administration: meetingData.administration,
        comment: meetingData.comment,
        divisors: meetingData.divisors,
        place: meetingData.place,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "EDIT_MEETING",
        payload: {
          meeting: response.data.meeting,
          id: meetingData.meeting_id,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const importAgendaFromTemplate = (form_data) => async (dispatch) => {
  dispatch({ type: "MEETING_LOADING" });
  await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/importAgendaFromTemplate`,
      form_data,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      if (response.data.success === true) {
        dispatch({ type: "MEETING_LOADING_ABORT" });
      }
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const exportParticipantList = (meeting_id) => async (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants/export`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((res) => {
      let date = new Date().toLocaleDateString("de-DE", {
        day: "2-digit",
        year: "numeric",
        month: "2-digit",
      });
      fileDownload(
        res.data,
        date + "Teilnehmerliste-Versammlung" + meeting_id + ".pdf"
      );
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};
