import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Table,
} from "reactstrap";
import AddWeg from "./Forms/AddWeg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWegs, deleteWeg, addWeg, editWeg } from "../actions/wegAction";
import { showOrganisation } from "../actions/organisationAction";
import { useSortableData } from "../_hooks";
import TextInput from "./Inputs/TextInput";

const Weg = (props) => {
  const wegInitValues = {
    name: "",
    power_of_proxy: "",
    suffrage: "",
  };

  const [modal, setModal] = useState(false);
  const [wegData, setWegData] = useState(wegInitValues);
  const [wegId, setWegId] = useState("");
  const { wegs, isWaiting } = useSelector((state) => state.wegs);
  const [filteredWegs, setFilteredWegs] = useState(wegs);

  const { organisation } = useSelector((state) => state.organisation);
  const { items, requestSort, sortConfig } = useSortableData(filteredWegs);

  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "WEG_LOADING" });
    dispatch(getWegs());
    dispatch(showOrganisation());

    return function cleanup() {
      dispatch({ type: "CLEAR_ERRORS" });
      dispatch({ type: "CLEAR_WEGS" });
    };
  }, [dispatch]);

  useEffect(() => {
    if (modal && !isWaiting) {
      setModal(false);
    } // eslint-disable-next-line
  }, [isWaiting]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let searchWegs = wegs.filter((weg) => {
        return weg.name.toLowerCase().includes(query.toLowerCase());
      });

      setFilteredWegs(searchWegs);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query, wegs]);

  const toggle = () => setModal(!modal);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const sortIcon = (name) => {
    if (getClassNamesFor(name) === 0) {
      return "";
    } else if (getClassNamesFor(name) === "descending") {
      return "mdi mdi-arrow-up-thick";
    } else if (getClassNamesFor(name) === "ascending") {
      return "mdi mdi-arrow-down-thick";
    }
  };

  const handleSearchQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  /**
   *
   * Handler
   *
   */

  const handleClose = () => {
    setWegData(wegInitValues);
    setWegId("");
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({ type: "WEG_LOADING_ABORT" });
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setWegData((prevState) => ({ ...prevState, [name]: value }));
  };
  const deleteButtonHandler = (e) => {
    dispatch(deleteWeg(e));
  };

  const editButtonHandler = (id, weg) => {
    setWegId(id);
    setWegData(weg);
    toggle();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "CLEAR_ERRORS" });
    if (wegId) {
      dispatch(editWeg(wegId, wegData));
    } else {
      dispatch(addWeg(wegData));
    }
  };

  const renderNextMeeting = (weg) => {
    let today = new Date();
    let date = "-";
    let time = "";
    if (weg.meetings.length !== 0) {
      for (let x = 0; x < weg.meetings.length; x++) {
        if (new Date(weg.meetings[x].date) >= today) {
          date = new Date(weg.meetings[x].date).toLocaleDateString("de-DE", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          time = weg.meetings[x].time + " Uhr";
          break;
        }
      }
      if (date === "-") {
        return (
          <td className="flex-td grayed-out">
            <i className="mdi mdi-calendar-today"></i>Ausstehend
          </td>
        );
      }
      return (
        <td className="flex-td">
          <i className="mdi mdi-calendar-today"></i>
          {date + ", " + time}
        </td>
      );
    } else {
      return (
        <td className="flex-td grayed-out">
          <i className="mdi mdi-calendar-today"></i>Ausstehend
        </td>
      );
    }
  };

  const renderWegs = () => {
    if (items.length) {
      return (
        <Table className="mb-0" responsive>
          <thead>
            <tr>
              <th
                className={getClassNamesFor("name")}
                onClick={() => requestSort("name")}
              >
                Name
                <i className={sortIcon("name")} />
              </th>
              <th
                className={getClassNamesFor("meetings_count")}
                onClick={() => requestSort("meetings_count")}
              >
                Versammlungen
                <i className={sortIcon("meetings_count")} />
              </th>
              <th>Nächste Versammlung</th>
              <th>Aktion</th>
            </tr>
          </thead>
          <tbody>
            {items.map((weg) => {
              return (
                <tr key={weg.id} className="weg-list">
                  <td>
                    <Link to={`/weg/${weg.id}`} className="flex-td">
                      <i className="mdi mdi-home-outline"></i>
                      {weg.name}
                    </Link>
                  </td>
                  <td>{weg.meetings_count}</td>
                  {renderNextMeeting(weg)}
                  <td className="tableColumnMinSize">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color="primary">
                        Aktion
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => editButtonHandler(weg.id, weg)}
                        >
                          Bearbeiten
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            if (
                              window.confirm(
                                "Sind Sie sicher, dass sie das Element löschen wollen? Es werden alle zugehörigen Versammlungen gelöscht!"
                              )
                            )
                              deleteButtonHandler(weg.id);
                          }}
                        >
                          Löschen
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    } else {
      if (!isWaiting) {
        return "Keine WEGs gefunden.";
      } else {
        return (
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        );
      }
    }
  };

  const renderWegModal = () => {
    if (modal) {
      return (
        <AddWeg
          onClosed={handleClose}
          modal={modal}
          toggle={toggle}
          handleInputChange={inputChangeHandler}
          handleSubmit={handleSubmit}
          inputValue={wegData}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Container fluid>
      <div className="headRow">
        <Row>
          <Col>
            <h1>WEG-Übersicht für {organisation ? organisation.name : ""}</h1>
          </Col>
        </Row>
        <div className="topButton">
          <Button color="success" onClick={toggle}>
            WEG anlegen
          </Button>
          {renderWegModal()}
        </div>
      </div>
      <Row className="overviewTable">
        <Col>
          <h2>
            Wohnungs&shy;ei&shy;gen&shy;tü&shy;mer&shy;ge&shy;mein&shy;schaften
          </h2>
        </Col>
        <Col md={2}>
          <TextInput
            name="Test"
            placeholder="Suche..."
            handleInputChange={(e) => {
              handleSearchQuery(e);
            }}
          />
        </Col>
        <Col>{renderWegs()}</Col>
      </Row>
    </Container>
  );
};

export default Weg;
