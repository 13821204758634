export const default_agenda_item_form_data = {
  agenda_item_id: null,
  title: "",
  order: "",
  divisor: "",
  decision_key: "",
  custom_decision_key: "",
  preliminary_remark: "",
  motion: "",
  notes: "",
  files: null,
};

export const default_participant_form_data = {
  name: "",
  object_number: "",
  list: null,
  divisor: [],
};

export const default_voting_form_data = {
  agenda_item_id: "",
  title: "",
  description: "",
  type: "",
  options_limit: 0,
  required: false,
  is_motion: false,
  send_motion: false,
  options: [],
  not_allowed_participants: [],
  motion: "",
};
