import PasswordInput from "../Inputs/PasswordInput";

const PasswordResetForm = ({setPassword, password, passwordConfirm, setPasswordConfirm,submit}) => {

    return (
        <form>
            <PasswordInput
                placeholder="Passwort"
                name="password"
                label="Passwort"
                value={password}
                handleInputChange={(e) =>  setPassword(e.target.value)}
            />

            <PasswordInput
                placeholder="Passwort bestätigen"
                name="password_confirm"
                label="Passwort bestätigen"
                value={passwordConfirm}
                handleInputChange={(e) =>  setPasswordConfirm(e.target.value)}
            />
            <button
                type="submit"
                className="btn btn-primary"
                onClick={submit}
            >Passwort ändern
            </button>
        </form>
    )
}


export default PasswordResetForm;