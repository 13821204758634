import { Modal, ModalBody, ModalHeader, Button, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editParticipant,
  deleteAllParticipants,
  getParticipants,
} from "../../../actions/participantAction";
import QrModal from "../QrModal";
import { Container } from "react-bootstrap";
import Pusher from "pusher-js";

import { TableForm, ImportForm, VoteForm, ParticipantTable } from ".";

const ParticipantModal = (props) => {
  const meeting = useSelector((state) => state.meetings.selected_meeting);
  const { isWaiting, participants } = useSelector(
    (state) => state.participants
  );
  const { votings } = useSelector((state) => state.votings);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showVote, setShowVote] = useState(false);
  const [show_qr, setShowQr] = useState(false);
  const [qr_value, setQrValue] = useState(123);
  const { errors } = useSelector((state) => state.errors);
  const { message, type } = useSelector((state) => state.alert);
  const [filteredParticipant, setFilteredParticipant] = useState(participants);
  const [query, setQuery] = useState("");

  const [editFormData, setEditFormData] = useState({
    id: null,
    name: "",
    object_number: "",
  });

  const [enableEdit, setEnableEdit] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe(`meeting-${meeting.id}`);

    channel.bind("participant.logout", function (data) {
      dispatch(getParticipants(meeting.id));
    });
  }, [dispatch, meeting.id]);

  useEffect(() => {
    if (!isWaiting && showForm) {
      toggleForm();
    }
    if (!isWaiting && showUpload) {
      toggleUpload();
    } // eslint-disable-next-line
  }, [isWaiting]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let searched_participants = participants.filter((participant) => {
        if (participant.name.toLowerCase().includes(query.toLowerCase()))
          return true;
        return participant.object_number
          .toLowerCase()
          .includes(query.toLowerCase());
      });

      setFilteredParticipant(searched_participants);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query, participants]);

  const handleSearchQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const toggleForm = () => {
    dispatch({ type: "CLEAR_ERRORS" });
    setShowForm(!showForm);
    setShowUpload(false);
    setShowQr(false);
    setShowVote(false);
  };

  const toggleUpload = () => {
    setShowUpload(!showUpload);
    setShowForm(false);
    setShowVote(false);
    setShowQr(false);
  };

  const toggleVote = (participant) => {
    setShowForm(false);
    setShowUpload(false);
    setShowQr(false);
    setShowVote(!showVote);
    setSelectedParticipant(participant);
  };

  const toggleQr = (value = null) => {
    setShowForm(false);
    setShowUpload(false);
    setShowVote(false);
    setQrValue(value);
    setShowQr(!show_qr);
  };

  const handleEditSubmit = () => {
    dispatch(editParticipant(editFormData));
    deactivateEditMode();
  };

  const renderQr = () => {
    if (show_qr && qr_value) {
      return <QrModal toggle={toggleQr} value={qr_value} />;
    } else {
      return null;
    }
  };

  const activateEditMode = (participant) => {
    setEditMode(true);
    setEditFormData((state) => ({
      ...state,
      name: participant.name,
      id: participant.id,
      object_number: participant.object_number,
    }));
  };

  const deactivateEditMode = () => {
    setEditMode(false);
    setEnableEdit(false);
    setEditFormData((state) => ({
      ...state,
      name: "",
      object_number: "",
      id: null,
    }));
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;

    setEditFormData((prevState) => ({ ...prevState, [name]: value }));
    setEnableEdit(true);
  };

  const modalTitle = () => {
    if (showUpload) {
      return "Teilnehmerliste: Teilnehmer importieren";
    }
    if (showForm) {
      return "Teilnehmerliste: Teilnehmer hinzufügen";
    } else {
      return "Teilnehmerliste";
    }
  };

  const handleDeleteAll = () => {
    dispatch(deleteAllParticipants(meeting.id));
  };

  const renderRemoveAllButton = () => {
    if (!meeting) return null;
    if (meeting.closed) {
      return "";
    }
    if (participants.length) {
      return (
        <Button
          color="danger"
          onClick={() => {
            if (
              window.confirm(
                "Sind Sie sicher, dass sie alle Teilnehmer löschen wollen?"
              )
            )
              handleDeleteAll();
          }}
        >
          Teilnehmerliste leeren
        </Button>
      );
    }
  };

  return (
    <Modal
      size="md"
      onExit={props.handleClose}
      isOpen={props.modal}
      backdrop="static"
      toggle={props.toggle}
      unmountOnClose={true}
      scrollable={true}
      className={
        showUpload || showForm
          ? "modal-dialog-centered"
          : "modal-dialog-centered xxl"
      }
      id="memberlist"
    >
      <ModalHeader toggle={props.toggle}>{modalTitle()}</ModalHeader>
      {message && (
        <Container fluid>
          <Row>
            <div className={`${type} alert-dismissible fade show`} role="alert">
              <p>{message}</p>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                data-bs-dismiss="alert"
                onClick={() => dispatch({ type: "ALERT_CLEAR" })}
              >
                <i className="mdi mdi-close" />
              </button>
            </div>
          </Row>
        </Container>
      )}
      <ModalBody>
        <ParticipantTable
          handleSearchQuery={handleSearchQuery}
          meeting={meeting}
          toggleUpload={toggleUpload}
          renderRemoveAllButton={renderRemoveAllButton}
          toggleForm={toggleForm}
          filteredParticipant={filteredParticipant}
          editMode={editMode}
          editFormData={editFormData}
          inputChangeHandler={inputChangeHandler}
          toggleQr={toggleQr}
          participants={participants}
          handleEditSubmit={handleEditSubmit}
          activateEditMode={activateEditMode}
          deactivateEditMode={deactivateEditMode}
          enableEdit={enableEdit}
        />
        {meeting && showForm && (
          <TableForm meeting={meeting} toggleForm={toggleForm} props={props} />
        )}
        {showUpload && !showForm && (
          <ImportForm
            props={props}
            errors={errors}
            toggleUpload={toggleUpload}
          />
        )}
        {showVote && (
          <VoteForm
            votings={votings}
            selectedParticipant={selectedParticipant}
            toggleVote={toggleVote}
          />
        )}
        {renderQr()}
      </ModalBody>
    </Modal>
  );
};

export default ParticipantModal;
