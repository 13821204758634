import axios from "axios";
import { errorBuilder } from "./errorAction";
import { getToken } from "../_services/userService";
import fileDownload from "js-file-download";

export const getAgendaItems = (meeting_id) => async (dispatch) => {
  dispatch({ type: "AGENDA_ITEMS_LOADING" });
  await axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/meeting_agenda/${meeting_id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "FETCH_AGENDA_ITEMS",
        payload: {
          agenda_items: response.data.agenda_items,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const addAgendaItem =
  (agenda_item_data, meetingId) => async (dispatch) => {
    dispatch({ type: "AGENDA_ITEMS_LOADING" });

    const formData = new FormData();
    formData.append("title", agenda_item_data.title);
    formData.append("order", agenda_item_data.order);
    formData.append("divisor", agenda_item_data.divisor);
    formData.append("motion", agenda_item_data.motion);
    formData.append("decision_key", agenda_item_data.decision_key);
    formData.append(
      "custom_decision_key",
      agenda_item_data.custom_decision_key
    );
    formData.append("notes", agenda_item_data.notes);
    formData.append("preliminary_remark", agenda_item_data.preliminary_remark);
    formData.append("meeting_id", meetingId);
    if (agenda_item_data.files) {
      for (let i = 0; i < agenda_item_data.files.length; i++) {
        formData.append(`files[${i}]`, agenda_item_data.files[i]);
      }
    }

    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/agenda_item`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((response) => {
        dispatch({
          type: "ADD_AGENDA_ITEM",
          payload: {
            agenda_items: response.data.agenda_items,
            id: meetingId,
          },
        });
      })
      .catch((error) => {
        dispatch(errorBuilder(error));
      });
  };

export const editAgendaItem = (agenda_item_data) => async (dispatch) => {
  dispatch({ type: "AGENDA_ITEMS_LOADING" });

  const formData = new FormData();
  formData.append("title", agenda_item_data.title);

  if (agenda_item_data.order === null) {
    formData.append("order", "");
  } else {
    formData.append("order", agenda_item_data.order);
  }

  if (agenda_item_data.notes === null) {
    formData.append("notes", "");
  } else {
    formData.append("notes", agenda_item_data.notes);
  }

  if (agenda_item_data.preliminary_remark === null) {
    formData.append("preliminary_remark", "");
  } else {
    formData.append("preliminary_remark", agenda_item_data.preliminary_remark);
  }

  if (agenda_item_data.motion === null) {
    formData.append("motion", "");
  } else {
    formData.append("motion", agenda_item_data.motion);
  }

  formData.append("divisor", agenda_item_data.divisor);
  formData.append("decision_key", agenda_item_data.decision_key);
  if (agenda_item_data.decision_key === "custom") {
    formData.append(
      "custom_decision_key",
      agenda_item_data.custom_decision_key
    );
  }

  if (agenda_item_data.files) {
    for (let i = 0; i < agenda_item_data.files.length; i++) {
      formData.append(`files[${i}]`, agenda_item_data.files[i]);
    }
  }

  await axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/agenda_item/${agenda_item_data.agenda_item_id}?_method=PATCH`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "EDIT_AGENDA_ITEM",
        payload: {
          agenda_items: response.data.agenda_items,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const getFile = (file_id, file_name) => async (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/file/${file_id}`, {
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((res) => {
      fileDownload(res.data, file_name);
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const deleteAgendaItem = (id) => (dispatch) => {
  dispatch({ type: "AGENDA_ITEMS_LOADING" });
  axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/agenda_item/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "DELETE_AGENDA_ITEM",
        payload: {
          id: id,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const deleteFile = (id) => (dispatch) => {
  dispatch({ type: "AGENDA_ITEMS_LOADING" });
  axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/remove_file/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "DELETE_AGENDA_ITEM_FILE",
        payload: {
          agenda_item: response.data.agenda_item,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};
