import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown,
  DropdownMenu,
} from "reactstrap";

export default function MeetingsTable({
  meetings,
  isWaiting,
  deleteButtonHandler,
  editButtonHandler,
}) {
  if (meetings.length) {
    return (
      <Table>
        <thead>
          <tr>
            <th className="tableColumnMinSize">ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Versammlungsart</th>
            <th>Termin</th>
            <th>Ort</th>
            <th class="tableColumnWithTooltip">
              P/VL
              <span class="tooltip">Protokollführung/Versammlungsleitung</span>
            </th>
            <th className="tableColumnMinSize">Aktion</th>
          </tr>
        </thead>
        <tbody>
          {meetings.map((meeting) => (
            <tr key={meeting.id} className="meeting-list">
              <td>{meeting.id}</td>
              <td>
                <Link to={`/versammlung/${meeting.id}`}>{meeting.title}</Link>
              </td>
              <td>
                {meeting.meeting_start && !meeting.closed && (
                  <span className="meetingStarted">Im Gange</span>
                )}
                {meeting.closed && (
                  <span className="meetingClosed">Geschlossen</span>
                )}
                {!meeting.closed && !meeting.meeting_start && "Steht aus"}
              </td>
              <td>{meeting.meeting_type}</td>
              <td className="flex-td">
                <i className="mdi mdi-calendar-today"></i>
                {new Date(meeting.date).toLocaleDateString()}, {meeting.time}{" "}
                Uhr
              </td>
              <td>{meeting.place}</td>
              <td>{meeting.meeting_leader}</td>
              <td>
                {meeting.closed ? (
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          "Sind Sie sicher, dass sie die Versammlung löschen wollen?"
                        )
                      )
                        deleteButtonHandler(meeting.id);
                    }}
                    color="danger"
                  >
                    Löschen
                  </Button>
                ) : (
                  <UncontrolledButtonDropdown>
                    <DropdownToggle caret color="primary">
                      Aktion
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => editButtonHandler(meeting.id)}
                      >
                        Bearbeiten
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          if (
                            window.confirm(
                              "Sind Sie sicher, dass sie die Versammlung löschen wollen?"
                            )
                          )
                            deleteButtonHandler(meeting.id);
                        }}
                      >
                        Löschen
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  } else {
    if (!isWaiting) {
      return "Zurzeit sind keine Versammlungen für Sie innerhalb dieser WEG angelegt";
    } else {
      return (
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      );
    }
  }
}
