import {Button, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import CheckboxInput from "../Inputs/CheckboxInput";
import MultiSelect from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/tooltip.scss";

import {useSelector} from "react-redux";

const AddVoting = (props) => {
    const errors = useSelector(state => state.errors);
    const [show_form, setShowForm] = useState(false);
    const [option_form_data, setOptionFormData] = useState({
        label: '',
    })

    const {participants} = useSelector(state => state.participants)
    const [participant_options, setParticipantOptions] = useState([])

    useEffect(() => {
        let option_object = participants.map(particpant => {
            return {label: particpant.name, value: particpant.id};

        })
        setParticipantOptions(option_object); // eslint-disable-next-line
    },[])

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === "type") {
            if ((props.formData.type === "select_default" || props.formData.type === "select" || props.formData.type === "multi_select") && value === "text") {
                props.setFormData(prevState => ({...prevState, options: []}));
            }
            if (props.formData.type === "text" && (value === "multi_select" || value === "select")) {
                props.setFormData(prevState => ({...prevState, options: []}));
            }
            if (value === 'read_and_confirm') {
                props.setFormData(prevState => ({...prevState, options: []}));
            }
        }

        if (name === "required") {
            props.setFormData(state => ({...state, required: !props.formData.required}))
        }
        if (name === "send_motion") {
            props.setFormData(state => ({...state, send_motion: !props.formData.send_motion}))
        } else if (name === "is_motion") {
            if (!props.formData.is_motion === true) {
                props.setFormData(state => ({
                    ...state,
                    is_motion: true,
                    description: props.formData.motion,
                    title: "Beschlussantrag"
                }))
            } else {
                props.setFormData(state => ({...state, is_motion: !props.formData.is_motion}))
            }
        } else if (name === "type" && value === "select_default") {
            props.setFormData(prevState => ({...prevState, options: [{label: "Ja"},{label: "Nein"},{label: "Enthaltung"}]}))
            props.setFormData(prevState => ({...prevState, [name]: "select"}));
        }
        else {
            props.setFormData(prevState => ({...prevState, [name]: value}));
        }

    }

    const handleOptionInutchange = (e) => {
        const {name, value} = e.target;
        setOptionFormData(prevState => ({...prevState, [name]: value}))
    }

    const toggleOptionForm = () => {
        setShowForm(!show_form);
    }

    const addOption = () => {
        const tmp_data = props.formData.options.concat(option_form_data);
        props.setFormData(prevState => ({...prevState, options: tmp_data}))
        setOptionFormData({
            label: '',
        })
    }

    const renderOptionLimitInput = () => {
        if (props.formData.type === "multi_select") {
            return (<SelectInput
                name="options_limit"
                value={props.formData.options_limit}
                label="Maximale Anzahl der auswählbaren Optionen"
                placeholder="Maximum eingeben"
                handleInputChange={handleInputChange}
                options={{
                    '0': 'unbegrenzt',
                    '1': '1',
                    '2': '2',
                    '3': '3',
                    '4': '4',
                    '5': '5',
                    '6': '6',
                    '7': '7',
                    '8': '8',
                    '9': '9',
                    '10': '10',

                }}
            />)
        }

        return null;
    }

    const deleteOption = (element_index) => {
        const tmp_data = props.formData.options.filter((option, key) => key !== element_index);
        props.setFormData(prevState => ({...prevState, options: tmp_data}))

    }

    const renderOptionTable = () => {
        if (props.formData.options.length) {

            return props.formData.options.map((item, index) => {
                return (
                    <div>
                        <span>{item.label}</span>
                        <Button color="danger" onClick={() => deleteOption(index)}>
                            <i className="dripicons-trash"></i>
                        </Button>
                    </div>
                )
            })
        }

    }

    const renderFormButton = () => {

        if (props.formData.type !== '') {
            if (props.formData.type === "text" && props.formData.options.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Text</strong> kann nur eine Option angegeben werden</span>
            } else if (props.formData.type === "read_and_confirm" && props.formData.options.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Bestätigen</strong> kann nur eine Option angegeben werden</span>
            } else {
                if (show_form) {
                    return <Button color="danger" onClick={toggleOptionForm}>Abbrechen</Button>
                } else {
                    return <Button color="primary" onClick={toggleOptionForm}>Antwortmöglichkeit hinzufügen</Button>
                }
            }

        } else {
            return <Button color="primary" disabled={true}>Bitte Typ auswählen...</Button>
        }

    }

    const renderForm = () => {
        if (show_form) {
            if (props.formData.type === 'select' || props.formData.type === 'multi_select') {

                return (
                    <>
                        <p>Antwortmöglichkeit</p>
                        <div>
                            <TextInput
                                placeholder="Antwortmöglichkeit eingeben"
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                            />
                            <Button onClick={addOption} color="primary"><i
                                className="dripicons-checkmark"></i></Button>
                        </div>
                    </>
                )

            } else if (props.formData.type === 'read_and_confirm') {
                if (props.formData.options.length <= 0) {
                    return (
                        <div>
                            <TextAreaInput
                                placeholder="Text eingeben"
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                                rows={3}
                                cols={50}
                            />
                            <Button className="textarea-button" onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            } else {
                if (props.formData.options.length <= 0) {
                    return (
                        <div>
                            <TextInput
                                placeholder="Label eingeben"
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                            />
                            <Button onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            }
        }
    }

    const change = (e) => {
        props.setFormData(prevState => ({...prevState, not_allowed_participants: e}));
    }

    const renderOptionFeedback = () => {
        let feedBack_style = {
            color: "#fa5c7c",
            fontSize: "0.75rem",
            fontWeight: "400"

        }

        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors["options"]) {
                return (
                    <div>
                        <p style={feedBack_style}>Bitte geben Sie Antwortmöglichkeiten an.</p>
                    </div>
                )
            }
        }
    }



    return (
        <Modal size="lg" id="addVotingModal" onExit={props.handleClose} isOpen={props.modal} backdrop="static" toggle={props.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={props.toggle}>Abstimmung erstellen</ModalHeader>
            <ModalBody>
                <Form onSubmit={props.handleSubmit}>
                    <Row>
                        <Col>
                            <div className="beschlussantrag-infobox">
                                {!props.formData.motion_exists ? <CheckboxInput
                                   name="is_motion"
                                   label="Ist diese Abstimmung ein Beschlussantrag?"
                                   handleInputChange={handleInputChange}
                                   value=""
                                   checked={props.formData.is_motion}
                                /> : <p><strong>Beachten Sie:</strong><br/>Es wurde bereits ein Beschlussantrag für diesen TOP angelegt.</p>}
                                {!props.formData.motion_exists ? <p>Ist der Haken angewählt, wird der Beschlussvorschlag in die Beschreibung übernommen (Änderung ist möglich)</p> : ""}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{display:'flex'}}>
                           <CheckboxInput
                                name="send_motion"
                                label="Beschreibung der Abstimmung an App senden?"
                                handleInputChange={handleInputChange}
                                value=""
                                checked={props.formData.send_motion}
                            />
                            <FontAwesomeIcon data-tip data-for="sendToAppTip" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="sendToAppTip" place="top" effect="solid">
                                Ist der Haken angewählt, können die Teilnehmer die Beschreibung der Abstimmung in der Abstimmungs-App sehen.
                            </ReactTooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >Abstimmungstitel*</p>
                            <FontAwesomeIcon data-tip data-for="registerTip" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="registerTip" place="top" effect="solid">
                                Hier sollte die Abstimmung kurz und knapp beschrieben werden.
                            </ReactTooltip>

                            <TextInput
                                name="title"
                                value={props.formData.title}
                                placeholder="Titel eingeben"
                                handleInputChange={handleInputChange}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >Beschreibung</p>
                            <FontAwesomeIcon data-tip data-for="description" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="description" place="top" effect="solid">
                                Hier kann der Gegenstand der Abstimmung beschrieben werden.
                            </ReactTooltip>
                            <TextAreaInput
                                name="description"
                                value={props.formData.description}
                                placeholder="Beschreibung eingeben"
                                handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >Art der Abstimmung*</p>
                            <FontAwesomeIcon data-tip data-for='choices' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="choices" place="top" effect="solid">
                                Hier können die Auswahlmöglichkeiten für Teilnehmer eingestellt werden.
                            </ReactTooltip>
                            <div className="no-label">
                                <SelectInput
                                    name="type"
                                    value={props.formData.type}
                                    placeholder="Art der Abstimmung auswählen"
                                    handleInputChange={handleInputChange}
                                    options={{
                                        'select_default': 'Einfache Auswahl (Ja / Nein / Enthaltung)',
                                        'select': 'Einfache Auswahl (Eigene Optionen)',
                                        'multi_select': 'Mehrfach Auswahl',
                                        'read_and_confirm': 'Bestätigung durch Teilnehmer',
                                        'text': 'Texteingabe durch Teilnehmer',
                                    }}
                                />
                            </div>

                        </Col>

                        <Col>
                            <div className="form-group">
                                <p className="d-inline-block mb-1">Ausgeschlossene Teilnehmer</p>
                                <FontAwesomeIcon id="not_allowed" data-tip data-for="not_allowed" className="ml-1 custtooltip-trigger" icon={faInfoCircle}/>
                                <ReactTooltip className="custtooltip" id="not_allowed" place="top" effect="solid">
                                    Hier können einzelne Teilnehmer von der Abstimmung ausgeschlossen werden.
                                </ReactTooltip>
                                <MultiSelect
                                   options={participant_options}
                                   value={props.formData.not_allowed_participants}
                                   onChange={change}
                                   labelledBy={'Select'}
                                   className="multiSelectDropdown"
                                   overrideStrings={{
                                       'allItemsAreSelected': 'Alle Teilnehmer wurden ausgewählt.',
                                       'clearSearch': 'Suche leeren',
                                       'noOptions': 'Keine Teilnehmer verfügbar',
                                       'search': 'Suchen',
                                       'selectAll': 'Alle auswählen',
                                       'selectSomeItems': 'Auswählen'
                                   }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderOptionLimitInput()}
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            {renderFormButton()}
                        </Col>
                    </Row>
                    <Row>
                        <Col id="voting_options">
                            {renderForm()}
                            {renderOptionTable()}
                            {renderOptionFeedback()}
                        </Col>
                    </Row>


                    <div className="processingButtons">
                        <Button color="lightgray" onClick={props.toggle}>Abbrechen</Button>
                        <Button color="primary" onClick={props.handleSubmit}>Erstellen</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default AddVoting;