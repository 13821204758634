import React, { useEffect, useState } from "react";
import Select from "react-select";

const ParticipantSelectInput = ({
  name,
  options,
  handleInputChange,
  participant,
}) => {
  const [selectedState, setSelectedState] = useState();

  useEffect(() => {
    if (participant.deputyable_type === "App\\Models\\User") {
      setSelectedState({
        value: 0,
        label: options.filter((op) => op.value === 0)[0].label,
      });
    } else if (participant.deputyable_id === null) {
      setSelectedState();
    } else {
      setSelectedState({
        value: participant.deputyable_id,
        label: options.filter((op) => op.value === participant.deputyable_id)[0]
          ?.label,
      });
    }
  }, [options]);

  const handleInternalchange = (selected) => {
    if (selected) {
      let { value, label } = selected;
      setSelectedState({ label: label, value: value });
      handleInputChange({ name: name, value: value });
    } else {
      setSelectedState();
      handleInputChange({ name: name, value: "" });
    }
  };
  const customStyles = {
    option: (provided, { data }) => ({
      ...provided,
      color: data.color,
    }),
  };

  return (
    <Select
      placeholder="Kein Vertreter"
      options={options}
      name={name}
      onChange={handleInternalchange}
      value={selectedState}
      isClearable={true}
      isSearchable={true}
      styles={customStyles}
    />
  );
};

export default ParticipantSelectInput;
