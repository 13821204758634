import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken} from "../_services/userService";


export const getVoteByParticipantAndVoting = (voting_id, participant_id) => async(dispatch) => {
    dispatch({type:"VOTE_LOADING"});
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get_by_participant_voting/${participant_id}/${voting_id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_VOTE",
                payload:{
                    vote: response.data.vote,
                }
            });

        }).catch(error => {
            dispatch(errorBuilder(error));
        });

} ;

export const getVoteByMeeting = (meeting_id) => async (dispatch) => {
    dispatch({type:"VOTE_LOADING"});
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/get_votes_by_meeting/${meeting_id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_VOTE_BY_MEETING",
                payload:{
                    votes: response.data.votes,
                }
            });

        }).catch(error => {
            dispatch(errorBuilder(error));
        });
}

export const getVotesByVoting = (voting_id) => async (dispatch) => {
    dispatch({type:"VOTE_LOADING"});
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vote/voting/${voting_id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_VOTE_BY_VOTING",
                payload:{
                    votes: response.data.votes,
                }
            });

        }).catch(error => {
            dispatch(errorBuilder(error));
        });
}


export const vote_for_participant = (voting_id, participant_id, vote) => async(dispatch) => {
    dispatch({type:"VOTE_LOADING"});
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/vote`, {
            voting_id: voting_id,
            participant_id: participant_id,
            vote:vote
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "VOTE",
                payload: {
                    vote: response.data.vote
                }
            });
            dispatch({type: "ALERT_SUCCESS", payload: {
                    message: "Abstimmung erfolgreich"
                }});
        }).catch(error => {
            dispatch(errorBuilder(error));
        });


};

export const multi_vote = (vote_data, meeting_id) => async (dispatch) => {
    dispatch({type:"VOTE_LOADING"});
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/multi_vote/${meeting_id}`, vote_data,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
            dispatch({type:"VOTE_LOADING_ABORT"});
            dispatch({
                type: "USER_DEPUTYS_VOTED",
                payload: {
                    deputed_votes: response.data.votes
                }
            })
        dispatch({type: "ALERT_SUCCESS", payload: {
            message: "Abstimmung erfolgreich"
        }});
        }).catch(error => {
            dispatch(errorBuilder(error));
        });


};