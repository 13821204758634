import axios from "axios";
import { errorBuilder } from "./errorAction";
import { getToken } from "../_services/userService";

export const getParticipants = (meeting_id) => async (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  await axios
    .get(
      `${process.env.REACT_APP_API_ENDPOINT}/participant_list/${meeting_id}`,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "FETCH_PARTICIPANTS",
        payload: {
          participants: response.data.participants,
          meeting_leader: response.data.meeting_leader,
          quorum: response.data.quorum,
        },
      });
    })
    .catch(function (error) {
      dispatch(errorBuilder(error));
    });
};

export const deleteParticipant = (id) => (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/participant/${id}`, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => {
      dispatch({
        type: "DELETE_PARTICIPANT",
        payload: {
          id: id,
          quorum: response.data.quorum,
        },
      });
      dispatch({
        type: "ALERT_SUCCESS",
        payload: {
          message: "Teilnehmer gelöscht.",
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const deleteAllParticipants = (meeting_id) => (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/all_participants`,
      {
        meeting_id: meeting_id,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "DELETE_ALL_PARTICIPANT",
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const addParticipant =
  (participant_data, meeting_id) => async (dispatch) => {
    dispatch({ type: "PARTICIPANTS_LOADING" });
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/participant`,
        {
          name: participant_data.name,
          object_number: participant_data.object_number,
          meeting_id: meeting_id,
          divisors: participant_data.divisor,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "ADD_PARTICIPANT",
          payload: {
            participant: response.data.participant,
            quorum: response.data.quorum,
          },
        });
        dispatch({
          type: "ALERT_SUCCESS",
          payload: {
            message: "Teilnehmer erfolgreich angelegt.",
          },
        });
      })
      .catch((error) => {
        dispatch(errorBuilder(error));
      });
  };

export const importParticipant =
  (meeting_id, participant_list) => async (dispatch) => {
    dispatch({ type: "PARTICIPANTS_LOADING" });
    const formData = new FormData();
    formData.append("meeting_id", meeting_id);
    formData.append("file", participant_list, participant_list.name);

    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/import_participant_list`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "IMPORT_PARTICIPANTS",
          payload: {
            participants: response.data.participants,
            quorum: response.data.quorum,
          },
        });
        dispatch({
          type: "ALERT_SUCCESS",
          payload: {
            message: "Teilnehmer Import erfolgreich.",
          },
        });
      })
      .catch((error) => {
        dispatch(errorBuilder(error));
      });
  };

export const editParticipant = (participant_data) => async (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/participant/${participant_data.id}`,
      {
        name: participant_data.name,
        object_number: participant_data.object_number,
        deputyable_id: participant_data.deputyable_id,
        deputyable_type: participant_data.deputy_type,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      if (response.data.participant.length > 0) {
        dispatch({
          type: "EDIT_PARTICIPANTS",
          payload: {
            participants: response.data.participant,
            id: participant_data.id,
            quorum: response.data.quorum,
          },
        });
      } else {
        dispatch({
          type: "EDIT_PARTICIPANT",
          payload: {
            participant: response.data.participant,
            id: participant_data.id,
            quorum: response.data.quorum,
          },
        });
      }
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const editParticipantDeputy = (participant_data) => async (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/participant/${participant_data.id}`,
      {
        deputyable_id: participant_data.deputyable_id,
        deputyable_type: participant_data.deputy_type,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      if (response.data.participant.length > 0) {
        dispatch({
          type: "EDIT_PARTICIPANTS",
          payload: {
            participants: response.data.participant,
            id: participant_data.id,
            quorum: response.data.quorum,
          },
        });
      } else {
        dispatch({
          type: "EDIT_PARTICIPANT",
          payload: {
            participant: response.data.participant,
            id: participant_data.id,
            quorum: response.data.quorum,
          },
        });
      }
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const changeParticipantPresents = (id, value) => async (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/update_presence/${id}`,
      { present: value },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "EDIT_PARTICIPANT",
        payload: {
          participant: response.data.participant,
          quorum: response.data.quorum,
          id: id,
        },
      });
    })
    .catch((error) => {
      dispatch(errorBuilder(error));
    });
};

export const getParticipantForDeputyVoteModalByMeeting =
  (meeting_id) => async (dispatch) => {
    dispatch({ type: "PARTICIPANTS_LOADING" });
    await axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/participant/get_for_deputy_vote_by_meeting/${meeting_id}`,
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((response) => {
        dispatch({
          type: "FETCH_MANUAL_PARTICIPANTS",
          payload: {
            participants: response.data.participants,
          },
        });
      })
      .catch(function (error) {
        dispatch(errorBuilder(error));
      });
  };

export const forceParticipantLogout = (participantId) => async (dispatch) => {
  dispatch({ type: "PARTICIPANTS_LOADING" });
  await axios
    .patch(
      `${process.env.REACT_APP_API_ENDPOINT}/participant_logout/${participantId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: "EDIT_PARTICIPANT",
        payload: {
          participant: response.data.participant,
          quorum: response.data.quorum,
          id: participantId,
        },
      });
    })
    .catch(function (error) {
      dispatch(errorBuilder(error));
    });
};
