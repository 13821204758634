import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import {
  addAgendaItem,
  editAgendaItem,
  getAgendaItems,
} from "../actions/agendaItemAction";
import {
  addParticipant,
  getParticipants,
  importParticipant,
} from "../actions/participantAction";
import { showMeeting } from "../actions/meetingAction";
import { addVoting, getVotingByMeeting } from "../actions/votingAction";
import { getUserDeputys } from "../actions/userAction";
import { showWeg } from "../actions/wegAction";
import {
  default_agenda_item_form_data,
  default_participant_form_data,
  default_voting_form_data,
} from "../_utils/defaultObjects";
import AddAgendaItem from "../components/Forms/AddAgendaItem";
import AddVoting from "../components/Forms/AddVoting";
import Result from "../components/Modals/Result";
import ParticipantModal from "../components/Modals/ParticipantModal/ParticipantModal";
import DeputyVoteModal from "../components/Modals/DeputyVoteModal";
import MeetingData from "../components/Meeting/MeetingData";

export default function MeetingPage(props) {
  const [form_data, setFormData] = useState(default_agenda_item_form_data);
  const [participant_form_data, setParticipantFormData] = useState(
    default_participant_form_data
  );
  const [voting_form_data, setVotingFormData] = useState(
    default_voting_form_data
  );
  const [modal, setModal] = useState(false);
  const [participantModal, setParticipantModal] = useState(false);
  const [votingModal, setVotingModal] = useState(false);
  const { participants, quorum } = useSelector((state) => state.participants);
  const meeting = useSelector((state) => state.meetings.selected_meeting);
  const { agenda_items } = useSelector((state) => state.agenda_items);
  const wating_for_agenda_items = useSelector(
    (state) => state.agenda_items.isWaiting
  );
  const wating_for_votings = useSelector((state) => state.votings.isWaiting);
  const [showResult, setShowResult] = useState(false);
  const [deputyVote, setDeputyVote] = useState(false);
  const [deputyVote_enabled, setDeputyVoteEnabled] = useState(false);
  const [votingTemplateImportModal, setVotingTemplateImportModal] =
    useState(false);
  const [trigger_reload, setTriggerReload] = useState(false);
  const weg = useSelector((state) => state.wegs.selected_weg);

  const dispatch = useDispatch();

  useEffect(() => {
    if (meeting) {
      if (!weg) {
        dispatch(showWeg(meeting.weg_id));
      } else {
        if (weg.id !== meeting.weg_id) {
          dispatch(showWeg(meeting.weg_id));
        }
      }
    }
    // eslint-disable-next-line
  }, [meeting]);

  useEffect(() => {
    dispatch(getUserDeputys(props.match.params.meetingId));
    //dispatch(getParticipantForDeputyVoteModalByMeeting(props.match.params.meetingId))
    if (participants) {
      let filterd_participants = participants.filter((participant) => {
        if (participant.app_login === 0 && participant.present === 1) {
          return true;
        }
        return false;
      });
      if (filterd_participants.length > 0) {
        setDeputyVoteEnabled(true);
      } else {
        setDeputyVoteEnabled(false);
      }
    }
    // eslint-disable-next-line
  }, [participants]);

  useEffect(() => {
    if (trigger_reload === true) {
      dispatch(getAgendaItems(props.match.params.meetingId));
      dispatch(getVotingByMeeting(props.match.params.meetingId));
      setTriggerReload(false);
    }
    // eslint-disable-next-line
  }, [trigger_reload]);

  useEffect(() => {
    dispatch(getParticipants(props.match.params.meetingId));
    dispatch(showMeeting(props.match.params.meetingId));
    dispatch(getAgendaItems(props.match.params.meetingId));
    dispatch(getVotingByMeeting(props.match.params.meetingId));

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe(`meeting-${props.match.params.meetingId}`);

    channel.bind("app.login", function (data) {
      dispatch(getParticipants(props.match.params.meetingId));
    });

    return function cleanup() {
      dispatch({ type: "CLEAR_ERRORS" });
      dispatch({ type: "CLEAR_AGENDA_ITEMS" });
      dispatch({ type: "CLEAR_MEETINGS" });
      dispatch({ type: "CLEAR_VOTINGS" });
      dispatch({ type: "CLEAR_PARTICIPANTS" });
      dispatch({ type: "ALERT_CLEAR" });
      channel.unbind();
      pusher.unsubscribe(channel);
      pusher.disconnect();
    };
  }, [dispatch, props.match.params.meetingId]);

  useEffect(() => {
    if (!wating_for_agenda_items && modal) {
      setModal(false);
    }
    // eslint-disable-next-line
  }, [wating_for_agenda_items]);

  useEffect(() => {
    if (!wating_for_votings && votingModal) {
      setVotingModal(false);
    }
    // eslint-disable-next-line
  }, [wating_for_votings]);

  const toggle = () => setModal(!modal);
  const toggleParticipantForm = () => {
    setParticipantModal(!participantModal);
  };
  const toggleVotingForm = () => setVotingModal(!votingModal);
  const toggleShowResult = () => setShowResult(!showResult);
  const toggelDeputyVote = () => setDeputyVote(!deputyVote);
  const toggleAgendaItemTemplateImportModal = () =>
    setVotingTemplateImportModal(!votingTemplateImportModal);

  /**
   * Handler
   *
   */

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const agendaItemInputChangeHandler = (e) => {
    const { name, value } = e.target;

    //Special Case: Decision key cannot have the value "double_qualified" when Divisor is  "Nach Köpfen" or "Nach Einheit"
    if (
      name === "decision_key" &&
      value === "double_qualified" &&
      (form_data.divisor === "Nach Köpfen" ||
        form_data.divisor === "Nach Einheit")
    ) {
      setFormData((prevState) => ({ ...prevState, divisor: "" }));
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleParticipantInputChange = (e) => {
    const { name, value } = e.target;
    setParticipantFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDivisorChange = (e, i) => {
    const { name, value } = e.target;
    const tmp_data = participant_form_data.divisor;
    tmp_data[i] = { [name]: value };
    setParticipantFormData((prevState) => ({
      ...prevState,
      divisor: tmp_data,
    }));
  };

  const handleClose = () => {
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({ type: "ALERT_CLEAR" });
    dispatch({ type: "AGENDA_ITEMS_LOADING_ABORT" });
    setFormData(default_agenda_item_form_data);
    setParticipantFormData(default_participant_form_data);
    setVotingFormData(default_voting_form_data);
  };

  const editButtonHandler = (id) => {
    let agenda_item = agenda_items.filter((item) => item.id === id);
    agenda_item = agenda_item[0];
    setFormData({
      agenda_item_id: agenda_item.id,
      title: agenda_item.title,
      order: agenda_item.order,
      motion: agenda_item.motion,
      divisor: agenda_item.divisor,
      decision_key: agenda_item.decision_key,
      custom_decision_key: agenda_item.custom_decision_key,
      preliminary_remark: agenda_item.preliminary_remark,
      notes: agenda_item.notes,
      files: null,
    });
    toggle();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form_data.agenda_item_id) {
      dispatch(editAgendaItem(form_data));
    } else {
      dispatch(addAgendaItem(form_data, props.match.params.meetingId));
    }
  };

  const handleVotingSubmit = (e) => {
    e.preventDefault();
    dispatch(addVoting(voting_form_data));
  };

  const handleFileChange = (name, file) => {
    setFormData((prevState) => ({ ...prevState, [name]: file }));
  };

  const handleParticipantFileChange = (name, file) => {
    setParticipantFormData((prevState) => ({ ...prevState, [name]: file }));
  };

  const handleFileUpload = () => {
    if (participant_form_data.participant_list) {
      dispatch(
        importParticipant(
          props.match.params.meetingId,
          participant_form_data.participant_list
        )
      );
    }
  };

  const handleParticipantSubmit = (e) => {
    dispatch({ type: "CLEAR_ERRORS" });
    dispatch({ type: "ALERT_CLEAR" });
    dispatch(
      addParticipant(participant_form_data, props.match.params.meetingId)
    );
    setParticipantFormData(default_participant_form_data);
  };

  return (
    <Container fluid>
      {showResult && (
        <Result
          toggle={toggleShowResult}
          modal={showResult}
          handleClose={handleClose}
        />
      )}
      {modal && (
        <AddAgendaItem
          modal={modal}
          toggle={toggle}
          values={form_data}
          handleInputChange={agendaItemInputChangeHandler}
          handleFileChange={handleFileChange}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          handleEdit={editButtonHandler}
        />
      )}

      {participantModal && (
        <ParticipantModal
          participants={participants}
          modal={participantModal}
          toggle={toggleParticipantForm}
          values={participant_form_data}
          handleClose={handleClose}
          handleInputChange={handleParticipantInputChange}
          handleFileUpload={handleFileUpload}
          handleFileChange={handleParticipantFileChange}
          handleSubmit={handleParticipantSubmit}
          handleDivisorChange={handleDivisorChange}
        />
      )}

      {deputyVote && (
        <DeputyVoteModal toggle={toggelDeputyVote} modal={deputyVote} />
      )}

      {votingModal && (
        <AddVoting
          modal={votingModal}
          toggle={toggleVotingForm}
          formData={voting_form_data}
          setFormData={setVotingFormData}
          handleClose={handleClose}
          handleSubmit={handleVotingSubmit}
          handleInputChange={inputChangeHandler}
        />
      )}

      {meeting && (
        <MeetingData
          meeting={meeting}
          participants={participants}
          quorum={quorum}
          toggleParticipantForm={toggleParticipantForm}
          weg={weg}
          toggle={toggle}
          agenda_items={agenda_items}
          wating_for_agenda_items={wating_for_agenda_items}
          toggelDeputyVote={toggelDeputyVote}
          toggleVotingForm={toggleVotingForm}
          toggleShowResult={toggleShowResult}
          editButtonHandler={editButtonHandler}
          deputyVote_enabled={deputyVote_enabled}
          setVotingFormData={setVotingFormData}
          setTriggerReload={setTriggerReload}
          votingTemplateImportModal={votingTemplateImportModal}
          toggleAgendaItemTemplateImportModal={
            toggleAgendaItemTemplateImportModal
          }
        />
      )}
    </Container>
  );
}
