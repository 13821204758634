import React from "react";
import { Table, Button, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { exportParticipantList } from "../../../actions/meetingAction";
import {
  changeParticipantPresents,
  editParticipantDeputy,
} from "../../../actions/participantAction";
import { useSortableData } from "../../../_hooks";
import { ImportParticipantBtn, AddParticipantBtn, EditActionBtn } from ".";
import ParticipantSelectInput from "../../Inputs/ParticipantSelectInput";
import TextInput from "../../Inputs/TextInput";

export default function ParticipantTable({
  handleSearchQuery,
  meeting,
  toggleUpload,
  renderRemoveAllButton,
  toggleForm,
  filteredParticipant,
  editMode,
  editFormData,
  inputChangeHandler,
  toggleQr,
  participants,
  handleEditSubmit,
  activateEditMode,
  deactivateEditMode,
  enableEdit,
}) {
  const dispatch = useDispatch();
  const { items, requestSort, sortConfig } =
    useSortableData(filteredParticipant);

  const downloadParticipantListButtonHandler = () => {
    dispatch(exportParticipantList(meeting.id));
  };

  const togglePresents = (id, value) => {
    dispatch(changeParticipantPresents(id, !value));
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return undefined;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const sortIcon = (name) => {
    if (getClassNamesFor(name) === 0) {
      return "";
    } else if (getClassNamesFor(name) === "descending") {
      return "mdi mdi-arrow-up-thick";
    } else if (getClassNamesFor(name) === "ascending") {
      return "mdi mdi-arrow-down-thick";
    }
  };

  const buildOptions = (id) => {
    let participantsOptions = [];

    participantsOptions.push({
      value: 0,
      label: meeting.meeting_leader + " (Verwaltung)",
      color: "#000000",
    });
    if (participants.length) {
      for (let i = 0; i < participants.length; i++) {
        if (participants[i].id !== id) {
          if (participants[i].deputyable_id === null) {
            if (participants[i].deputys?.length) {
              participantsOptions.push({
                value: participants[i].id,
                label: participants[i].name,
                color: "#FF0000",
              });
            } else {
              participantsOptions.push({
                value: participants[i].id,
                label: participants[i].name,
                color: "#000000",
              });
            }
          }
        }
      }
    }
    return participantsOptions;
  };

  const changeDeputy = (deputy) => {
    const { name, value } = deputy;
    let form_data = {};
    if (value === 0) {
      form_data = {
        id: parseInt(name),
        deputy_type: "User",
        deputyable_id: 99, // Fake Deputy as Meeting Leader
        deputy_id: 0,
      };
    } else if (value !== 0) {
      form_data = {
        id: parseInt(name),
        deputy_type: "",
        deputyable_id: value,
        deputy_id: value,
      };
    }

    dispatch(editParticipantDeputy(form_data));
  };

  return (
    <>
      <Row>
        <Col>
          <TextInput
            name="Test"
            placeholder="Suche..."
            handleInputChange={(e) => {
              handleSearchQuery(e);
            }}
          />
          <ImportParticipantBtn meeting={meeting} toggleUpload={toggleUpload} />
          <Button onClick={downloadParticipantListButtonHandler}>
            Teilnahmecodes exportieren
          </Button>
          <AddParticipantBtn meeting={meeting} toggleForm={toggleForm} />
          {renderRemoveAllButton()}
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="my-4 vollmacht">
            <strong>Hinweis Vollmachten an externe Dritte:</strong>
            <br />
            Namen der externen Personen können durch eine Ergänzung im
            Namensfeld des Eigentümers gekennzeichnet werden.
            <br />
            z.B. "Max Mustermann vertr. d. Erna Müller"
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <thead className="thNoBorder">
              <tr>
                <th
                  className={getClassNamesFor("name")}
                  onClick={() => requestSort("name")}
                >
                  Name
                  <i className={sortIcon("name")} />
                </th>

                <th
                  className={getClassNamesFor("object_number")}
                  onClick={() => requestSort("object_number")}
                >
                  Wohnungsnummer
                  <i className={sortIcon("object_number")} />
                </th>
                <th>Teiler</th>
                <th className="tableColumnMinSize">Codes</th>
                {meeting?.closed ? (
                  ""
                ) : (
                  <th className="tableColumnMinSize">Anwesenheit</th>
                )}
                <th>Vertreter</th>
                {meeting?.closed ? (
                  ""
                ) : (
                  <th className="tableColumnMinSize">Aktion</th>
                )}
              </tr>
            </thead>
            <tbody>
              {items.map((participant) => {
                return (
                  <tr key={participant?.id}>
                    <td>
                      {editMode && editFormData?.id === participant?.id ? (
                        <TextInput
                          value={editFormData?.name}
                          handleInputChange={inputChangeHandler}
                          name="name"
                        />
                      ) : (
                        participant?.name
                      )}
                      {/* If online, add class "online" */}
                      {participant?.present ? (
                        !editMode || editFormData?.id !== participant?.id ? (
                          <span className="onlineStatus online" />
                        ) : (
                          <>
                            {participant?.app_login ? (
                              <i className="mdi mdi-cellphone"></i>
                            ) : undefined}
                          </>
                        )
                      ) : (
                        <span className="onlineStatus" />
                      )}
                    </td>
                    <td>
                      {editMode && editFormData?.id === participant?.id ? (
                        <TextInput
                          value={editFormData?.object_number}
                          handleInputChange={inputChangeHandler}
                          name="object_number"
                        />
                      ) : (
                        participant?.object_number
                      )}
                    </td>
                    <td>
                      {participant?.divisors?.map((divisor) => {
                        return (
                          <p key={divisor?.id}>
                            {divisor?.name}: {divisor?.value}
                          </p>
                        );
                      })}
                    </td>
                    <td className="tdQrCode">
                      {participant?.token ? (
                        <i
                          onClick={() => toggleQr(participant?.token)}
                          className="mdi mdi-qrcode-scan"
                        ></i>
                      ) : null}
                      <span>{participant?.token}</span>
                    </td>

                    {meeting?.closed ? (
                      ""
                    ) : participant?.deputyable_id ? (
                      <td>&nbsp;</td>
                    ) : (
                      <td className="tdPresent">
                        <input
                          type="checkbox"
                          aria-invalid="true"
                          name="present"
                          disabled={meeting?.meeting_start ? false : true}
                          onChange={() => {
                            togglePresents(
                              participant?.id,
                              participant?.present
                            );
                          }}
                          checked={participant?.present ? true : false}
                          title={
                            meeting?.meeting_start
                              ? "" // Empty tooltip if the meeting is started
                              : "Bitte Versammlung starten, um Anwesenheitsliste bearbeiten zu können"
                          }
                        />
                      </td>
                    )}

                    <td>
                      {participant?.present ? (
                        "Anwesende Teilnehmer können nicht vertreten werden!"
                      ) : (
                        <ParticipantSelectInput
                          options={buildOptions(participant?.id)}
                          handleInputChange={changeDeputy}
                          name={participant?.id}
                          participant={participant}
                        />
                      )}
                    </td>
                    {meeting?.closed ? (
                      ""
                    ) : (
                      <td className="editButtons">
                        <EditActionBtn
                          participant={participant}
                          meeting={meeting}
                          editMode={editMode}
                          editFormData={editFormData}
                          enableEdit={enableEdit}
                          handleEditSubmit={handleEditSubmit}
                          activateEditMode={activateEditMode}
                          deactivateEditMode={deactivateEditMode}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
