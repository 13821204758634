import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

const ProfileDropdown = ({name}) => {

    const ProfileMenus = [
        /*{
            label: 'My Account',
            icon: 'uil uil-user',
            redirectTo: '/',
        },
        {
            label: 'Settings',
            icon: 'uil uil-cog',
            redirectTo: '/',
        },
        {
            label: 'Support',
            icon: 'uil uil-life-ring',
            redirectTo: '/',
        },
        {
            label: 'Lock Screen',
            icon: 'uil uil-lock-alt',
            redirectTo: '/',
        },*/
        {
            label: 'Logout',
            icon: 'uil uil-exit',
            redirectTo: '/logout',
        },
    ];



    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }


    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
                data-toggle="dropdown"
                tag="button"
                className="nav-link dropdown-toggle nav-user arrow-none mr-0"
                onClick={toggleDropdown}
                aria-expanded={dropdownOpen}>
                <span>
                        <span className="account-user-name">{name}</span>
                    </span>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                <div onClick={toggleDropdown}>
                    {ProfileMenus.map((item, i) => {
                        return (
                            <Link
                                to={item.redirectTo}
                                className="dropdown-item notify-item"
                                key={i + '-profile-menu'}>
                                <i className={`${item.icon} mr-1`}></i>
                                <span>{item.label}</span>
                            </Link>
                        );
                    })}
                </div>
            </DropdownMenu>
        </Dropdown>
    )
}

export default ProfileDropdown;
