import logo from "../assets/images/vote_logo.svg";
import {Container} from 'reactstrap';
import {Link} from "react-router-dom";
import {isLogin} from "../_services/userService";
import {useSelector} from "react-redux";
import ProfileDropdown from "./ProfileDropdown";

const Topbar = () => {

    const {user} = useSelector(state => state.user);



    const renderUser = () => {
        if (!isLogin()) return null;
        if (user && Object.keys(user).length === 0 && user.constructor === Object) return null;
        return (
            <ProfileDropdown name={user.name}/>
        )

    }

    return (
        <div className="navbar-custom topnav-navbar">
            <Container fluid>
                <Link to={"/"} className="topnav-logo">
                    <span className="topnav-logo-lg">
                        <img src={logo} height="30" alt="Logo"/>
                    </span>
                    <span className="topnav-logo-sm">
                        <img src={logo} height="30" alt="Logo"/>
                    </span>
                </Link>
                <ul className={"top-nav"}>
                    <li>
                        <Link to={"/"}>WEGs</Link>
                    </li>
                    <li>
                        <Link to={"/template"}>Vorlagen</Link>
                    </li>
                </ul>
                <ul className="list-unstyled topbar-right-menu float-right mb-0">
                    <li className="notification-list">
                        {renderUser()}
                    </li>
                </ul>
            </Container>
        </div>
    );
}

export default Topbar;