import {Button, Modal, ModalBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import SingeVoteForm from "../Forms/SingeVoteForm";
import {useDispatch, useSelector} from "react-redux";
import {getVoteByParticipantAndVoting, vote_for_participant} from "../../actions/voteAction";


const SingleVoteModal = (props) => {

    const dispatch = useDispatch();
    const {vote} = useSelector(state => state.vote);
    const {agenda_items} = useSelector(state => state.agenda_items)
    const [selected_option, setSelectedOption] = useState([]);
    const [voting_divisor, setVotingDivisor] = useState([]);

    useEffect(() => {
        if (props.voting) {
            dispatch(getVoteByParticipantAndVoting(props.voting.id, props.participant.id))
        }

        let ai = agenda_items.find(element => element.id === props.voting.agenda_item_id);
        setVotingDivisor(ai.divisor);
// eslint-disable-next-line
    }, [dispatch])


    const handleSubmit = () => {
        dispatch(vote_for_participant(props.voting.id, props.participant.id, selected_option));
        props.toggle();
    }

    const renderForm = () => {

        if (!props.voting) {
            return "Kein Voting gestartet";
        }


        let notAllowed = [];
        let participant_divisor = props.participant.divisors.find(div => div.name === voting_divisor);
        if (props.voting.not_allowed_participants) {
            notAllowed = props.voting.not_allowed_participants.split(',')
        }

        if (notAllowed.includes(props.participant.id.toString())) {
            return "Der Teilnehmer ist von der Abstimmung ausgeschlossen";
        }

        if ((voting_divisor !== "Nach Köpfen" && voting_divisor !== "Nach Einheit") && !participant_divisor) {
            return "Der Teilnehmer besitzt nicht den passenden Teiler: " + voting_divisor;
        }

        if (participant_divisor.value === '0') {
            return "Der Wert des Teiler " + voting_divisor + " für diesen Teilnehmer ist 0.";
        }

        if (props.voting) {
            if (vote.length !== 0) {
                return "Der Teilnehmer hat bereits abgestimmt";
            } else {
                return <SingeVoteForm participant_id={props.participant.id} voting={props.voting} selected_option={selected_option} setSelectedOption={setSelectedOption}/>
            }
        } else {
            return "Kein Voting gestartet";
        }
    }

    const renderButton = () => {

        if (props.voting) {
            if (vote.length !== 0) {
                return (
                    <div className="processingButtons qrButton">
                        <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
                    </div>
                )
            } else {
                return (
                    <div className="processingButtons qrButton">
                        <Button color="primary" onClick={handleSubmit}>Abstimmen</Button>
                        <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
                    </div>
                )
            }
        } else {
            return (
                <div className="processingButtons qrButton">
                    <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
                </div>
            )
        }
    }

    const renderHeadline = () => {
        if (props.voting) {
            return <h2 className="votingModalvotingTitle">Abstimmung: {props.voting.title} für {props.participant.name}</h2>
        } else
            return null;
    }


    return (
        <Modal size="m" isOpen={true} backdrop="static" className="modal-dialog-centered">
            <ModalBody>
                <div>
                    {renderHeadline()}
                    {renderForm()}
                    {renderButton()}
                </div>
            </ModalBody>
        </Modal>);
};


export default SingleVoteModal;