import React from "react";
import { Switch } from "react-router-dom";
import Weg from "./Weg";
import MeetingList from "./MeetingList";
import MeetingPage from "../routes/MeetingPage";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import SingleResult from "./SingleResult";
import Logout from "./Logout";
import Template from "./Template";
import AgendAItemTemplate from "./AgendAItemTemplate";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const Routing = () => (
  <Switch>
    <PublicRoute restricted={true} component={Login} path="/login" exact />
    <PublicRoute
      restricted={true}
      component={ForgotPassword}
      path="/passwort-vergessen"
      exact
    />
    <PublicRoute
      path="/password-reset/:token/:email"
      restricted={true}
      component={ResetPassword}
    />
    <PrivateRoute component={Weg} path="/" exact />
    <PrivateRoute path="/weg/:wegId" exact component={MeetingList} />
    <PrivateRoute
      path="/versammlung/:meetingId"
      exact
      component={MeetingPage}
    />
    <PrivateRoute path="/result/:voting_id" exact component={SingleResult} />
    <PrivateRoute path="/logout" exact component={Logout} />
    <PrivateRoute path="/template" exact component={Template} />
    <PrivateRoute
      path="/agenda_item_template/:agenda_item_template_id"
      exact
      component={AgendAItemTemplate}
    />
  </Switch>
);

export default Routing;
