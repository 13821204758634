import React from "react";
import { Button, Row, Col } from "reactstrap";
import TextInput from "../../Inputs/TextInput";
import DivisorInput from "../../Inputs/DivisorInput";

export default function TableForm({ meeting, toggleForm, props }) {
  let divisors = [];

  if (meeting.divisors) {
    divisors = meeting.divisors.split(",");
  }
  return (
    <>
      <Row>
        <Col sm="6">
          <TextInput
            errors={props.errors}
            handleInputChange={props.handleInputChange}
            label={null}
            name="name"
            placeholder="Name eingeben"
            value={props.values.name}
          />
        </Col>
        <Col sm="6">
          <TextInput
            errors={props.errors}
            handleInputChange={props.handleInputChange}
            label={null}
            name="object_number"
            placeholder="Nummer eingeben"
            value={props.values.object_number}
          />
        </Col>
      </Row>
      <Row>
        {divisors.map((item, key) => (
          <Col>
            <DivisorInput
              errors={props.errors}
              handleInputChange={props.handleDivisorChange}
              label={item}
              name={item}
              placeholder="Nummer eingeben"
              index={key}
              value={props.values.divisor}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="processingButtons">
          <Button color="lightgray" onClick={toggleForm}>
            Zurück
          </Button>
          <Button color="primary" onClick={props.handleSubmit}>
            Teilnehmer anlegen
          </Button>
        </Col>
      </Row>
    </>
  );
}
