import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../Inputs/TextInput";
import RadioInput from "../Inputs/RadioInput";
import { getVotesByVoting, multi_vote } from "../../actions/voteAction";
import { useSortableData } from "../../_hooks";
import { Container, Row } from "react-bootstrap";
import { getParticipantForDeputyVoteModalByMeeting } from "../../actions/participantAction";

const DeputyVoteModal = ({ toggle, modal, distinct_participant_list }) => {
  const { votings, selected_voting } = useSelector((state) => state.votings);
  const { votes: votes_by_voting, isWaiting } = useSelector(
    (state) => state.vote
  );
  const { agenda_items } = useSelector((state) => state.agenda_items);
  const { deputys } = useSelector((state) => state.user);
  const [votes, setVotes] = useState({});
  const [index, setIndex] = useState(0);
  const { selected_meeting } = useSelector((state) => state.meetings);
  const [submitted, setSubmitted] = useState(false);
  const { type } = useSelector((state) => state.errors);
  const [select_participant, setSelectParticipant] = useState({});
  const [select_deputy, setSelectDeputy] = useState({});
  const [selected_all, setSelectedAll] = useState(false);
  const [selected_all_deputy, setSelectedAllDeputy] = useState(false);
  const [voting_divisor, setVotingDivisor] = useState([]);
  const { message } = useSelector((state) => state.alert);
  const { isWaiting: participantsWaiting, manual_participants } = useSelector(
    (state) => state.participants
  );
  const message_type = useSelector((state) => state.alert.type);
  const [changed_vote, setChangeVote] = useState(false);
  const [selected_participant_list, setSelectedParticipantList] = useState([]);
  const [selected_deputy_list, setSelectedDeputyList] = useState([]);
  const [name_distinct_participants, setNameDistinctParticipants] = useState(
    []
  );
  const [name_distinct_deputies, setNameDistinctDeputies] = useState([]);
  const [paginate, setPaginate] = useState(null);
  const { items, requestSort, sortConfig } =
    useSortableData(selected_deputy_list);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParticipantForDeputyVoteModalByMeeting(selected_meeting.id));
    dispatch(getVotesByVoting(selected_voting.id));
    dispatch({ type: "VOTE_LOADING_ABORT" });
    return function cleanUp() {
      dispatch({ type: "CLEAR_ERRORS" });
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let distinct_list = manual_participants.filter(
      (ele, ind) =>
        ind === manual_participants.findIndex((elem) => elem.name === ele.name)
    );
    setNameDistinctParticipants(distinct_list);
    let distinct_deputy_list = deputys.filter(
      (ele, ind) => ind === deputys.findIndex((elem) => elem.name === ele.name)
    );
    setNameDistinctDeputies(distinct_deputy_list);
  }, [manual_participants]);

  useEffect(() => {
    setChangeVote(false);
    //setFilteredVotes(votes_by_meeting.filter(item => item.voting_id === selected_voting.id));
    if (isWaiting === false && submitted === true) {
      setVotes({});
      dispatch(getVotesByVoting(votings[index]?.id));
      setSubmitted(false);
      setSelectedAllDeputy(false);
      setSelectedAll(false);
    } // eslint-disable-next-line
  }, [isWaiting, submitted]);

  useEffect(() => {
    setChangeVote(false);
    setVotes({});
    setSubmitted(false);
    setSelectedAllDeputy(false);
    setSelectedAll(false);
    if (paginate === "up") {
      if (index + 1 < votings.length) {
        dispatch({
          type: "SELECT_VOTING",
          payload: { voting: votings[index + 1] },
        });
        dispatch(getVotesByVoting(votings[index + 1]?.id));
      } else {
        dispatch(getVotesByVoting(votings[index]?.id));
      }

      setPaginate(null);
    } else if (paginate === "down") {
      if (index - 1 >= 0) {
        dispatch({
          type: "SELECT_VOTING",
          payload: { voting: votings[index - 1] },
        });
        dispatch(getVotesByVoting(votings[index - 1]?.id));
      } else {
        dispatch(getVotesByVoting(votings[index]?.id));
      }
      setPaginate(null);
    }
  }, [paginate]);

  useEffect(() => {
    for (let i = 0; i < votings.length; i++) {
      if (votings[i].id === selected_voting.id) {
        setIndex(i);
        break;
      }
    }
    //setFilteredVotes(votes_by_meeting.filter(item => item.voting_id === selected_voting.id));
    let ai = agenda_items.find(
      (element) => element.id === selected_voting.agenda_item_id
    );
    setVotingDivisor(ai.divisor);
    if (ai.divisor === "Nach Köpfen") {
      setSelectedParticipantList(name_distinct_participants);
      setSelectedDeputyList(name_distinct_deputies);
    } else {
      setSelectedParticipantList(manual_participants);
      setSelectedDeputyList(deputys);
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    selected_voting,
    participantsWaiting,
    manual_participants,
    name_distinct_participants,
  ]);

  useEffect(() => {
    let build_votes_array = {};
    for (let j = 0; j < votes_by_voting.length; j++) {
      if (votes_by_voting[j].voting_id === selected_voting.id) {
        if (selected_voting.type === "select") {
          if (votes_by_voting[j].vote_options.length !== 0) {
            build_votes_array = {
              ...build_votes_array,
              [votes_by_voting[j].participant_id]:
                votes_by_voting[j].vote_options[0].voting_option_id +
                "." +
                votes_by_voting[j].vote_options[0].value,
            };
          }
        } else if (selected_voting.type === "read_and_confirm") {
          if (votes_by_voting[j].vote_options.length !== 0) {
            build_votes_array = {
              ...build_votes_array,
              [votes_by_voting[j].participant_id +
              "." +
              votes_by_voting[j].vote_options[0].voting_option_id]:
                votes_by_voting[j].vote_options[0].value,
            };
          }
        } else if (selected_voting.type === "text") {
          if (votes_by_voting[j].vote_options.length !== 0) {
            build_votes_array = {
              ...build_votes_array,
              [votes_by_voting[j].participant_id +
              "." +
              votes_by_voting[j].vote_options[0].voting_option_id]:
                votes_by_voting[j].vote_options[0].value,
            };
          }
        } else if (selected_voting.type === "multi_select") {
          if (votes_by_voting[j].vote_options.length !== 0) {
            votes_by_voting[j].vote_options.forEach((vote_option) => {
              build_votes_array = {
                ...build_votes_array,
                [votes_by_voting[j].participant_id +
                "." +
                vote_option.voting_option_id]: vote_option.value,
              };
            });
          }
        }
      }
    }
    setVotes(build_votes_array);
  }, [votes_by_voting, selected_voting]);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const sortIcon = (name) => {
    if (getClassNamesFor(name) === 0) {
      return "";
    } else if (getClassNamesFor(name) === "descending") {
      return "mdi mdi-arrow-up-thick";
    } else if (getClassNamesFor(name) === "ascending") {
      return "mdi mdi-arrow-down-thick";
    }
  };

  const textInputChangeHandler = (e) => {
    const { name, value } = e.target;
    setChangeVote(true);
    if (value.length === 0) {
      let new_votes = votes;
      delete new_votes[name];
      setVotes(new_votes);
    } else {
      setVotes({
        ...votes,
        [name]: value,
      });
    }
  };

  const selectInputChangeHandler = (e) => {
    const { name, value } = e.target;
    setChangeVote(true);
    setVotes({
      ...votes,
      [name]: value,
    });
  };

  const readInputChangeHandler = (e) => {
    const { name } = e.target;
    setChangeVote(true);
    if (votes[name]) {
      setVotes({
        ...votes,
        [name]: false,
      });
    } else {
      setVotes({
        ...votes,
        [name]: true,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "CLEAR_ERRORS" });
    if (changed_vote) {
      let vote_data = {};

      for (const [key, ent] of Object.entries(votes)) {
        let tmp = key.split(".");
        let ent_split = [];

        if (ent != true && ent != false) {
          let pointIndex = ent.indexOf(".");
          ent_split[0] = ent.substring(0, pointIndex);
          ent_split[1] = ent.substring(pointIndex + 1);
        }
        if (ent === false) {
          continue;
        }

        if (tmp[0] in select_participant) {
          continue;
        }

        //selectedPartcipants
        if (tmp[0] === "participant_selection") {
          for (const [index] of Object.entries(select_participant)) {
            if (ent_split.length > 1 && ent_split[0]) {
              if (vote_data[index]) {
                vote_data[index].push({
                  voting_option_id: ent_split[0],
                  value: ent_split[1],
                });
              } else {
                vote_data[index] = [];
                vote_data[index].push({
                  voting_option_id: ent_split[0],
                  value: ent_split[1],
                });
              }
            } else {
              if (vote_data[index]) {
                vote_data[index].push({ voting_option_id: tmp[1], value: ent });
              } else {
                vote_data[index] = [];
                vote_data[index].push({ voting_option_id: tmp[1], value: ent });
              }
            }
          }

          continue;
        }

        if (tmp[0] === "deputy_selection") {
          for (const [index] of Object.entries(select_deputy)) {
            if (ent_split.length > 1 && ent_split[0]) {
              if (vote_data[index]) {
                vote_data[index].push({
                  voting_option_id: ent_split[0],
                  value: ent_split[1],
                });
              } else {
                vote_data[index] = [];
                vote_data[index].push({
                  voting_option_id: ent_split[0],
                  value: ent_split[1],
                });
              }
            } else {
              if (vote_data[index]) {
                vote_data[index].push({ voting_option_id: tmp[1], value: ent });
              } else {
                vote_data[index] = [];
                vote_data[index].push({ voting_option_id: tmp[1], value: ent });
              }
            }
          }

          continue;
        }

        if (ent_split.length > 1 && ent_split[0]) {
          if (vote_data[tmp[0]]) {
            vote_data[tmp[0]].push({
              voting_option_id: ent_split[0],
              value: ent_split[1],
            });
          } else {
            vote_data[tmp[0]] = [];
            vote_data[tmp[0]].push({
              voting_option_id: ent_split[0],
              value: ent_split[1],
            });
          }
        } else {
          if (vote_data[tmp[0]]) {
            vote_data[tmp[0]].push({ voting_option_id: tmp[1], value: ent });
          } else {
            vote_data[tmp[0]] = [];
            vote_data[tmp[0]].push({ voting_option_id: tmp[1], value: ent });
          }
        }
      }
      const form_data = {
        voting_id: selected_voting.id,
        votes: vote_data,
      };
      dispatch(multi_vote(form_data, selected_meeting.id));
    }

    setSelectParticipant({});
    setSelectDeputy({});
    setSubmitted(true);
  };

  const addDeputySelection = (e) => {
    setSelectedAllDeputy(false);
    const { name, value } = e.target;
    if (name in select_deputy) {
      setSelectDeputy((prevState) => {
        const { [name]: remove, ...rest } = prevState;
        return rest;
      });
    } else {
      setSelectDeputy((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };

  const addParticipantSelection = (e) => {
    setSelectedAll(false);
    const { name, value } = e.target;
    if (name in select_participant) {
      setSelectParticipant((prevState) => {
        const { [name]: remove, ...rest } = prevState;
        return rest;
      });
    } else {
      setSelectParticipant((prevState) => {
        return { ...prevState, [name]: value };
      });
    }
  };

  const renderDeputySelection = () => {
    if (Object.keys(select_deputy).length === 0) return null;
    return (
      <tr className="groupVotingRow" key={0}>
        <td colSpan="3">
          <span>Abstimmen für:</span>
          <ul>
            {Object.keys(select_deputy).map((item) => {
              return <li>{select_deputy[item]}</li>;
            })}
          </ul>
        </td>
        {renderVotingOptions("deputy_selection")}
      </tr>
    );
  };

  const renderParticipantSelection = () => {
    if (Object.keys(select_participant).length === 0) return null;
    return (
      <tr className="groupVotingRow" key={0}>
        <td colSpan="3">
          <span>Abstimmen für:</span>
          <ul>
            {Object.keys(select_participant).map((item) => {
              return <li>{select_participant[item]}</li>;
            })}
          </ul>
        </td>
        {renderVotingOptions("participant_selection")}
      </tr>
    );
  };

  const renderVotingTitle = () => {
    if (!selected_voting) return null;
    let top = agenda_items.filter(
      (item) => item.id === selected_voting.agenda_item_id
    );
    top = top[0];

    return (
      <>
        TOP {top.order}: {top.title} (Abstimmung {index + 1} von{" "}
        {votings.length})
      </>
    );
  };

  const selectAllDeputy = () => {
    let notAllowed = [];
    if (selected_voting.not_allowed_participants) {
      notAllowed = selected_voting.not_allowed_participants.split(",");
    }
    let new_votes = votes;

    if (selected_all_deputy) {
      setSelectedAllDeputy(false);
      setSelectDeputy({});
      delete new_votes["deputy_selection"];
      setVotes(new_votes);
    } else {
      setSelectedAllDeputy(true);
      setSelectDeputy({});
      for (let i = 0; i < selected_deputy_list.length; i++) {
        let deputy_filtered_votes = [];
        let deputy_divisor = selected_deputy_list[i].divisors.find(
          (div) => div.name === voting_divisor
        );
        if (votes_by_voting) {
          deputy_filtered_votes = votes_by_voting.filter(
            (item) => item.participant_id === selected_deputy_list[i].id
          );
        }

        if (deputy_filtered_votes.length !== 0) {
          continue;
        }

        if (notAllowed.includes(selected_deputy_list[i].id.toString())) {
          continue;
        }

        if (
          voting_divisor !== "Nach Köpfen" &&
          voting_divisor !== "Nach Einheit"
        ) {
          if (!deputy_divisor) {
            continue;
          }
          if (deputy_divisor.value === "0") {
            continue;
          }
        }
        delete new_votes[selected_deputy_list[i].id];
        setSelectDeputy((prevState) => {
          return {
            ...prevState,
            [selected_deputy_list[i].id]: selected_deputy_list[i].name,
          };
        });
      }
      setVotes(new_votes);
    }
  };

  const selectAll = () => {
    let notAllowed = [];
    if (selected_voting.not_allowed_participants) {
      notAllowed = selected_voting.not_allowed_participants.split(",");
    }

    let new_votes = votes;

    if (selected_all) {
      setSelectedAll(false);
      setSelectParticipant({});
      delete new_votes["participant_selection"];
      setVotes(new_votes);
    } else {
      setSelectedAll(true);
      setSelectParticipant({});
      for (let i = 0; i < selected_participant_list.length; i++) {
        let deputy_filtered_votes = [];
        let participant_divisor = selected_participant_list[i].divisors.find(
          (div) => div.name === voting_divisor
        );

        if (votes_by_voting) {
          deputy_filtered_votes = votes_by_voting.filter(
            (item) => item.participant_id === selected_participant_list[i].id
          );
        }

        if (deputy_filtered_votes.length !== 0) {
          continue;
        }

        if (notAllowed.includes(selected_participant_list[i].id.toString())) {
          continue;
        }

        if (
          voting_divisor !== "Nach Köpfen" &&
          voting_divisor !== "Nach Einheit"
        ) {
          if (!participant_divisor) {
            continue;
          }

          if (participant_divisor.value === "0") {
            continue;
          }
        }
        //remove voting of Participant

        delete new_votes[selected_participant_list[i].id];

        setSelectParticipant((prevState) => {
          return {
            ...prevState,
            [selected_participant_list[i].id]:
              selected_participant_list[i].name,
          };
        });
      }
      setVotes(new_votes);
    }
  };

  const renderVoting = () => {
    if (!selected_voting) return null;

    let notAllowed = [];
    if (selected_voting.not_allowed_participants) {
      notAllowed = selected_voting.not_allowed_participants.split(",");
    }

    return (
      <div>
        <h2 className="votingModalvotingTitle">
          Abstimmung: {selected_voting.title}{" "}
          {selected_voting.stopped ? "(geschlossen)" : ""}
        </h2>
        <p>{selected_voting.description}</p>
        <p>
          <strong>
            Wenn Sie für mehrere Vertretene gleich abstimmen möchten, können Sie
            in der Auswahl-Spalte die gewünschten Personen auswählen und
            anschließend ganz unten für diese gleichzeitig abstimmen.
          </strong>
        </p>
        <p style={{ color: "#fa5c7c" }}>
          {selected_voting.options_limit
            ? `Maximal ${selected_voting.options_limit} Optionen wählbar`
            : ""}
        </p>
        <Table>
          {selected_voting.stopped === 1 ? (
            <th>&nbsp;</th>
          ) : (
            <th className="auswahl-th">
              <input
                type="checkbox"
                aria-invalid="true"
                onChange={() => selectAllDeputy()}
                checked={selected_all_deputy}
              />
              Auswahl
            </th>
          )}

          <th
            className={getClassNamesFor("name")}
            onClick={() => requestSort("name")}
          >
            Name
            <i className={sortIcon("name")} />
          </th>
          <th
            className={getClassNamesFor("object_number")}
            onClick={() => requestSort("object_number")}
          >
            Wohnungsnummer
            <i className={sortIcon("object_number")} />
          </th>
          {renderVotingOptionsHeader()}
          {items.map((deputy) => {
            let deputy_filtered_votes = [];
            if (votes_by_voting) {
              deputy_filtered_votes = votes_by_voting.filter(
                (item) => item.participant_id === deputy.id
              );
            }

            let deputy_divisor = deputy.divisors.find(
              (div) => div.name === voting_divisor
            );
            if (
              voting_divisor !== "Nach Köpfen" &&
              voting_divisor !== "Nach Einheit" &&
              !deputy_divisor
            ) {
              return (
                <tr key={deputy.id}>
                  <td>&nbsp;</td>
                  <td>{deputy.name}</td>
                  <td>{deputy.object_number}</td>
                  <td colSpan={selected_voting.voting_options.length}>
                    Der Teilnehmer besitzt nicht den passenden Teiler:{" "}
                    {voting_divisor}
                  </td>
                </tr>
              );
            }

            if (
              voting_divisor !== "Nach Köpfen" &&
              voting_divisor !== "Nach Einheit"
            ) {
              if (deputy_divisor.value === "0") {
                return (
                  <tr key={deputy.id}>
                    <td>&nbsp;</td>
                    <td>{deputy.name}</td>
                    <td>{deputy.object_number}</td>
                    <td colSpan={selected_voting.voting_options.length}>
                      Der Wert des Teilers {voting_divisor} für diesen
                      Teilnehmer ist 0.
                    </td>
                  </tr>
                );
              }
            }

            if (notAllowed.includes(deputy.id.toString())) {
              return (
                <tr key={deputy.id}>
                  <td>&nbsp;</td>
                  <td>{deputy.name}</td>
                  <td>{deputy.object_number}</td>
                  <td colSpan={selected_voting.voting_options.length}>
                    Der Teilnehmer ist von der Abstimmung augeschlossen
                  </td>
                </tr>
              );
            }

            if (selected_voting.stopped) {
              if (deputy_filtered_votes.length !== 0) {
                return (
                  <tr key={deputy.id}>
                    <td>&nbsp;</td>
                    <td>{deputy.name}</td>
                    <td>{deputy.object_number}</td>
                    {renderResult(deputy_filtered_votes)}
                  </tr>
                );
              }

              return (
                <tr key={deputy.id}>
                  <td>&nbsp;</td>
                  <td>{deputy.name}</td>
                  <td>{deputy.object_number}</td>
                  {deputy.id in select_participant ? (
                    <td colSpan={selected_voting.voting_options.length}>
                      &nbsp;
                    </td>
                  ) : (
                    renderVotingOptions(deputy.id)
                  )}
                </tr>
              );
            }

            return (
              <tr key={deputy.id}>
                <td>
                  <input
                    type="checkbox"
                    aria-invalid="true"
                    name={deputy.id}
                    value={deputy.name}
                    onChange={(e) => addDeputySelection(e)}
                    checked={deputy.id in select_deputy}
                  />
                </td>
                <td>{deputy.name}</td>
                <td>{deputy.object_number}</td>
                {deputy.id in select_deputy ? (
                  <td colSpan={selected_voting.voting_options.length}>
                    &nbsp;
                  </td>
                ) : (
                  renderVotingOptions(deputy.id)
                )}
              </tr>
            );
          })}

          {renderDeputySelection()}
        </Table>
      </div>
    );
  };

  const renderParticipantsNotAppLoggedIn = () => {
    if (!selected_voting) return null;
    if (participantsWaiting) {
      return (
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      );
    }

    let notAllowed = [];
    if (selected_voting.not_allowed_participants) {
      notAllowed = selected_voting.not_allowed_participants.split(",");
    }
    return (
      <div>
        <h2 className="votingModalvotingTitle">
          Als anwesend markierte Teilnehmer
        </h2>
        <Table>
          {selected_voting.stopped === 1 ? (
            <th>&nbsp;</th>
          ) : (
            <th className="auswahl-th">
              <input
                type="checkbox"
                aria-invalid="true"
                onChange={() => selectAll()}
                checked={selected_all}
              />
              Auswahl
            </th>
          )}
          <th>Name</th>
          <th>Wohnungsnummer</th>
          {renderVotingOptionsHeader()}
          {selected_participant_list.map((participant) => {
            let deputy_filtered_votes = [];
            if (votes_by_voting) {
              deputy_filtered_votes = votes_by_voting.filter(
                (item) => item.participant_id === participant.id
              );
            }
            let participant_divisor = participant.divisors.find(
              (div) => div.name === voting_divisor
            );

            if (
              voting_divisor !== "Nach Köpfen" &&
              voting_divisor !== "Nach Einheit" &&
              !participant_divisor
            ) {
              return (
                <tr
                  className={participant.is_deputy ? "deputy" : ""}
                  key={participant.id}
                >
                  <td>&nbsp;</td>
                  <td>{participant.name}</td>
                  <td>{participant.object_number}</td>
                  <td colSpan={selected_voting.voting_options.length}>
                    Der Teilnehmer besitzt nicht den passenden Teiler:{" "}
                    {voting_divisor}
                  </td>
                </tr>
              );
            }

            if (
              voting_divisor !== "Nach Köpfen" &&
              voting_divisor !== "Nach Einheit"
            ) {
              if (participant_divisor.value === "0") {
                return (
                  <tr
                    className={participant.is_deputy ? "deputy" : ""}
                    key={participant.id}
                  >
                    <td>&nbsp;</td>
                    <td>{participant.name}</td>
                    <td>{participant.object_number}</td>
                    <td colSpan={selected_voting.voting_options.length}>
                      Der Wert des Teilers {voting_divisor} für diesen
                      Teilnehmer ist 0.
                    </td>
                  </tr>
                );
              }
            }

            if (notAllowed.includes(participant.id.toString())) {
              return (
                <tr
                  className={participant.is_deputy ? "deputy" : ""}
                  key={participant.id}
                >
                  <td>&nbsp;</td>
                  <td>{participant.name}</td>
                  <td>{participant.object_number}</td>
                  <td colSpan={selected_voting.voting_options.length}>
                    Der Teilnehmer ist von der Abstimmung augeschlossen
                  </td>
                </tr>
              );
            }

            if (selected_voting.stopped) {
              if (deputy_filtered_votes.length !== 0) {
                return (
                  <tr
                    className={participant.is_deputy ? "deputy" : ""}
                    key={participant.id}
                  >
                    <td>&nbsp;</td>
                    <td>{participant.name}</td>
                    <td>{participant.object_number}</td>
                    {renderResult(deputy_filtered_votes)}
                  </tr>
                );
              }

              return (
                <tr
                  className={participant.is_deputy ? "deputy" : ""}
                  key={participant.id}
                >
                  <td>&nbsp;</td>
                  <td>{participant.name}</td>
                  <td>{participant.object_number}</td>
                  {participant.id in select_participant ? (
                    <td colSpan={selected_voting.voting_options.length}>
                      &nbsp;
                    </td>
                  ) : (
                    renderVotingOptions(participant.id)
                  )}
                </tr>
              );
            }

            return (
              <tr
                className={participant.is_deputy ? "deputy" : ""}
                key={participant.id}
              >
                <td>
                  <input
                    type="checkbox"
                    aria-invalid="true"
                    name={participant.id}
                    value={participant.name}
                    onChange={(e) => addParticipantSelection(e)}
                    checked={participant.id in select_participant}
                  />
                </td>
                <td>{participant.name}</td>
                <td>{participant.object_number}</td>
                {participant.id in select_participant ? (
                  <td colSpan={selected_voting.voting_options.length}>
                    &nbsp;
                  </td>
                ) : (
                  renderVotingOptions(participant.id)
                )}
              </tr>
            );
          })}
          {renderParticipantSelection()}
        </Table>
      </div>
    );
  };

  const renderResult = (result) => {
    if (selected_voting.type === "read_and_confirm") {
      return result[0].vote_options.map((item) => {
        if (item.value === "1") {
          return <td>Ja</td>;
        }

        return null;
      });
    }

    if (selected_voting.type === "text") {
      return result[0].vote_options.map((item) => {
        return <td>{item.value}</td>;
      });
    }

    if (selected_voting.type === "multi_select") {
      return selected_voting.voting_options.map((item) => {
        if (
          result[0].vote_options.filter((fi) => fi.voting_option_id === item.id)
            .length !== 0
        ) {
          return (
            <td style={{ textAlign: "center" }}>
              <i className="mdi mdi-check"></i>
            </td>
          );
        } else {
          return <td>&nbsp;</td>;
        }
      });
    }
    if (selected_voting.type === "select") {
      return selected_voting.voting_options.map((item) => {
        if (
          result[0].vote_options.filter((fi) => fi.voting_option_id === item.id)
            .length !== 0
        ) {
          return (
            <td style={{ textAlign: "center" }}>
              <i className="mdi mdi-check"></i>
            </td>
          );
        } else {
          return <td>&nbsp;</td>;
        }
      });
    }
  };

  const renderVotingOptionsHeader = () => {
    if (selected_voting.type === "multi_select") {
      return selected_voting.voting_options.map((item) => {
        return <th>{item.label}</th>;
      });
    } else if (selected_voting.type === "select") {
      return selected_voting.voting_options.map((item) => {
        return <th>{item.label}</th>;
      });
    } else if (selected_voting.type === "read_and_confirm") {
      return <th>Akzeptieren</th>;
    } else if (selected_voting.type === "text") {
      return <th>{selected_voting.voting_options[0].label}</th>;
    }
  };

  const renderVotingOptions = (deputy_id) => {
    if (isWaiting) {
      return (
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      );
    }

    if (selected_voting.stopped === 1)
      return <td colSpan={selected_voting.voting_options.length}>&nbsp;</td>;

    if (selected_voting.type === "multi_select") {
      let checkCount = 0;
      for (let i = 0; i < selected_voting.voting_options.length; i++) {
        if (!!votes[deputy_id + "." + selected_voting.voting_options[i].id]) {
          checkCount += 1;
        }
      }

      return selected_voting.voting_options.map((item) => {
        if (
          checkCount === selected_voting.options_limit &&
          selected_voting.options_limit !== 0
        ) {
          if (!!votes[deputy_id + "." + item.id]) {
            return (
              <td>
                <input
                  type="checkbox"
                  aria-invalid="true"
                  name={deputy_id + "." + item.id}
                  onChange={(e) => readInputChangeHandler(e)}
                  checked={!!votes[deputy_id + "." + item.id]}
                />
              </td>
            );
          } else {
            return (
              <td>
                <input
                  type="checkbox"
                  aria-invalid="true"
                  name={deputy_id + "." + item.id}
                  onChange={(e) => readInputChangeHandler(e)}
                  checked={!!votes[deputy_id + "." + item.id]}
                  disabled={true}
                />
              </td>
            );
          }
        }

        return (
          <td>
            <input
              type="checkbox"
              aria-invalid="true"
              name={deputy_id + "." + item.id}
              onChange={(e) => readInputChangeHandler(e)}
              checked={!!votes[deputy_id + "." + item.id]}
            />
          </td>
        );
      });
    } else if (selected_voting.type === "select") {
      return selected_voting.voting_options.map((item) => {
        return (
          <td>
            <RadioInput
              value={item.id + "." + item.value}
              name={deputy_id}
              handleInputChange={selectInputChangeHandler}
              checked={votes[deputy_id] === item.id + "." + item.value}
            />
          </td>
        );
      });
    } else if (selected_voting.type === "read_and_confirm") {
      return (
        <td>
          <input
            type="checkbox"
            aria-invalid="true"
            name={deputy_id + "." + selected_voting.voting_options[0].id}
            onChange={(e) => readInputChangeHandler(e)}
            checked={
              !!votes[deputy_id + "." + selected_voting.voting_options[0].id]
            }
          />
        </td>
      );
    } else if (selected_voting.type === "text") {
      return (
        <td>
          <TextInput
            name={deputy_id + "." + selected_voting.voting_options[0].id}
            value={
              votes[deputy_id + "." + selected_voting.voting_options[0].id]
            }
            handleInputChange={textInputChangeHandler}
          />
        </td>
      );
    }
  };

  const renderButtons = () => {
    if (selected_voting.stopped === 1) {
      return (
        <div className="processingButtons qrButton">
          <Button color="lightgray" onClick={toggle}>
            Schließen
          </Button>
        </div>
      );
    }

    return (
      <div className="processingButtons qrButton">
        <Button color="primary" onClick={handleSubmit}>
          Abstimmen
        </Button>
        {index === 0 ? (
          ""
        ) : (
          <Button color="lightgray" onClick={() => setPaginate("down")}>
            Zurück zur vorherigen Abstimmung
          </Button>
        )}

        {index + 1 === votings.length ? (
          ""
        ) : (
          <Button color="lightgray" onClick={() => setPaginate("up")}>
            Weiter zur nächsten Abstimmung
          </Button>
        )}
        <Button color="lightgray" onClick={toggle}>
          Schließen
        </Button>
      </div>
    );
  };

  if (participantsWaiting) {
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>;
  }

  return (
    <Modal
      isOpen={modal}
      backdrop="static"
      size="xl"
      toggle={toggle}
      className="modal-dialog-centered"
      id="deputyVoteModal"
    >
      <ModalHeader toggle={toggle}>{renderVotingTitle()}</ModalHeader>
      <ModalBody>
        {message && (
          <Container fluid>
            <Row>
              <div
                className={`${message_type} alert-dismissible fade show`}
                role="alert"
              >
                <p>{message}</p>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  data-bs-dismiss="alert"
                  onClick={() => dispatch({ type: "ALERT_CLEAR" })}
                >
                  <i className="mdi mdi-close" />
                </button>
              </div>
            </Row>
          </Container>
        )}

        {type && (
          <div className="alert alert-danger" role="alert">
            {type === "validation"
              ? "Bitte Stellen Sie sicher, dass sie alle Felder korrekt ausgefüllt haben."
              : "Ein Fehler ist aufgetreten."}
          </div>
        )}
        {renderVoting()}
        {renderParticipantsNotAppLoggedIn()}
        {renderButtons()}
      </ModalBody>
    </Modal>
  );
};

export default DeputyVoteModal;
