import React from "react";
import { Button, Row, Col } from "reactstrap";
import FileUploader from "../../Inputs/FileUploader";

export default function ImportForm({ props, errors, toggleUpload }) {
  return (
    <Row id="importParticipant">
      <Col>
        <FileUploader
          onFileUpload={(files) => {
            props.handleFileChange("participant_list", files[0]);
          }}
          errors={errors}
          max_files={1}
          name="file"
        />
        <div className="processingButtons">
          <Button color="lightgray" onClick={toggleUpload}>
            Zurück
          </Button>
          <Button color="primary" onClick={props.handleFileUpload}>
            Importieren
          </Button>
        </div>
      </Col>
    </Row>
  );
}
