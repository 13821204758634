import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { isLogin } from "../_services/userService";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
