import React from "react";
import { Button } from "reactstrap";

export default function AddParticipantBtn({ meeting, toggleForm }) {
  if (!meeting || meeting.closed) return null;
  if (
    meeting &&
    Object.keys(meeting).length === 0 &&
    meeting.constructor === Object
  )
    return null;

  return (
    <Button color="primary" onClick={toggleForm}>
      Teilnehmer hinzufügen
    </Button>
  );
}
