import {useEffect} from "react";
import {logoutAction} from "../actions/userAction";
import {useDispatch} from "react-redux";

const Logout = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: "CLEAR_ERRORS"});
        dispatch({type: "CLEAR_AGENDA_ITEMS"});
        dispatch({type: "CLEAR_MEETINGS"});
        dispatch({type: "CLEAR_VOTINGS"});
        dispatch({type: "CLEAR_PARTICIPANTS"});
        dispatch({type: "CLEAR_WEGS"});
        dispatch({type: "ALERT_CLEAR"});
        dispatch(logoutAction());

    },[dispatch])

    return (
        <></>
    )

};


export default Logout;