export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const getToken = () => {
  return JSON.parse(localStorage.getItem("token"));
};

export const isLogin = () => {
  return !!localStorage.getItem("user");
};

export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};
