import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Row, Col} from "react-bootstrap";
import {Button, Form, FormGroup} from 'reactstrap';
import FileUploader from "../Inputs/FileUploader";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import {useSelector} from "react-redux";
import SelectInput from "../Inputs/SelectInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "../../assets/scss/tooltip.scss";



const AddAgendaItem = (props) => {

    const meeting = useSelector(state => state.meetings.selected_meeting)

    const {errors} = useSelector(state => state.errors)

    if (!props.modal || meeting === null) {
        return null;

    }

    const renderCustomDecisionKeyField = () => {
        if (props.values.decision_key && (
            props.values.decision_key === 'custom')
        ) {
            return <TextInput name="custom_decision_key" value={props.values.custom_decision_key} errors={props.errors} handleInputChange={props.handleInputChange} placeholder="Prozent eingeben" />
        } else {
            return null
        }
    }

    const buildDivisors = () => {
        if(meeting.divisors) {
            let divisors = meeting.divisors;
            if(props.values.decision_key === 'double_qualified'){
                return divisors.split(",")
            }
            return divisors.split(",").concat('Nach Köpfen', 'Nach Einheit')
        }
        return ["Nach Köpfen","Nach Einheit"];

    }


    const buildDecisionKey = () => {
        if(meeting.divisors) {
            return {
                'simple': "Einfache Mehrheit",
                'double_qualified':"Doppelt qualifizierte Mehrheit",
                'all':"Allstimmigkeit",
                'custom':"Eigene Eingabe"

            }
        }

        return {
            'simple': "Einfache Mehrheit",
            'all':"Allstimmigkeit",
            'custom':"Eigene Eingabe"
        };
    }



    return (
      <Modal
        size="xl"
        onExit={props.handleClose}
        isOpen={props.modal}
        toggle={props.toggle}
        backdrop="static"
        className="modal-dialog-centered addTopModal"
      >
        <ModalHeader toggle={props.toggle}>
          Tagesordnungspunkt erstellen
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={props.handleSubmit}>
            <Row>
              <Col sm="12">
                <p className="d-inline-block mb-1">Name*</p>
                <FontAwesomeIcon
                  data-tip
                  data-for="top-name"
                  className="ml-1 custtooltip-trigger"
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className="custtooltip"
                  id="top-name"
                  place="top"
                  effect="solid"
                >
                  Hier sollte der TOP kurz und knapp beschrieben werden.
                </ReactTooltip>
                <TextAreaInput
                  name="title"
                  value={props.values.title}
                  label={false}
                  placeholder="Name eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </Col>
            </Row>
            <Row className="threeColumnRow">
              <Col lg="4">
                <p className="d-inline-block mb-1">TOP-Nummer*</p>
                <FontAwesomeIcon
                  data-tip
                  data-for="top-nr"
                  className="ml-1 custtooltip-trigger"
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className="custtooltip"
                  id="top-nr"
                  place="top"
                  effect="solid"
                >
                  Durch die Nummer der TOP wird die Sortierung erstellt z.B. TOP
                  1 oder TOP 2.2
                </ReactTooltip>
                <TextInput
                  name="order"
                  value={props.values.order}
                  label={false}
                  placeholder="5"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </Col>

              <Col lg="4">
                <p className="d-inline-block mb-1">Teiler*</p>
                <FontAwesomeIcon
                  data-tip
                  data-for="factor"
                  className="ml-1 custtooltip-trigger"
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className="custtooltip"
                  id="factor"
                  place="top"
                  effect="solid"
                >
                  Hier stehen die aus der Excel importierten Teilerschlüssel zur
                  Auswahl.
                </ReactTooltip>
                <div className="no-label">
                  <SelectInput
                    name="divisor"
                    value={props.values.divisor}
                    label={false}
                    placeholder="Bitte auswählen"
                    options={buildDivisors()}
                    errors={props.errors}
                    handleInputChange={props.handleInputChange}
                  />
                </div>
              </Col>

              <Col lg="4">
                <SelectInput
                  name="decision_key"
                  value={props.values.decision_key}
                  label="Abstimmungsschlüssel*"
                  options={buildDecisionKey()}
                  placeholder="Bitte auswählen"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
                {renderCustomDecisionKeyField()}
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="d-inline-block mb-1">Notizen (Intern)</p>
                <FontAwesomeIcon
                  data-tip
                  data-for="note"
                  className="ml-1 custtooltip-trigger"
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className="custtooltip"
                  id="note"
                  place="top"
                  effect="solid"
                >
                  Hier können Notizen für den internen Gebrauch eingetragen
                  werden. Sie sind nicht öffentlich sichtbar.
                </ReactTooltip>
                <TextAreaInput
                  name="notes"
                  value={props.values.notes}
                  label={false}
                  placeholder="Notizen eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="d-inline-block mb-1">Vorbemerkung</p>
                <FontAwesomeIcon
                  data-tip
                  data-for="remark"
                  className="ml-1 custtooltip-trigger"
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className="custtooltip"
                  id="remark"
                  place="top"
                  effect="solid"
                >
                  Hier können Vorbemerkungen zum TOP eingetragen werden, welche
                  im Protokoll ausgegeben werden.
                </ReactTooltip>
                <TextAreaInput
                  name="preliminary_remark"
                  value={props.values.preliminary_remark}
                  label={false}
                  placeholder="Vorbemerkung eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="d-inline-block mb-1">Beschlussvorschlag</p>
                <FontAwesomeIcon
                  data-tip
                  data-for="resolution"
                  className="ml-1 custtooltip-trigger"
                  icon={faInfoCircle}
                />
                <ReactTooltip
                  className="custtooltip"
                  id="resolution"
                  place="top"
                  effect="solid"
                >
                  Hier kann der Vorschlag eines Beschlussantrags eingetragen
                  werden.
                </ReactTooltip>
                <TextAreaInput
                  name="motion"
                  value={props.values.motion}
                  label={false}
                  placeholder="Vorschlag für Beschlussantrag eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <p className="d-inline-block mb-1">Dateien hochladen</p>
                  <FontAwesomeIcon
                    data-tip
                    data-for="upload"
                    className="ml-1 custtooltip-trigger"
                    icon={faInfoCircle}
                  />
                  <ReactTooltip
                    className="custtooltip"
                    id="upload"
                    place="top"
                    effect="solid"
                  >
                    Hier können Dateien für den internen Gebrauch hinterlegt
                    werden.
                  </ReactTooltip>
                  <FileUploader
                    onFileUpload={(files) => {
                      props.handleFileChange("files", files);
                    }}
                    name="files"
                    max_files={5}
                    errors={errors}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="processingButtons">
              <Button color="lightgray" onClick={props.toggle}>
                Abbrechen
              </Button>
              <Button color="primary">Erstellen</Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
}

export default AddAgendaItem;