import { logout } from "../_services/userService";
import { history } from "../_helpers";

export const errorBuilder = (error) => {
  // Error 😨

  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */

    if (error.response.status === 401) {
      logout();
      history.push("/login");
      return {
        type: "ALERT_ERROR",
        payload: {
          message: "Bitte loggen Sie sich ein.",
        },
      };
    }

    if (error.response.status === 429) {
      return {
        type: "ALERT_ERROR",
        payload: {
          message: "API Request Limit erreicht. Bitte warten Sie eine Minute.",
        },
      };
    }

    if (error.response.status === 422) {
      return {
        type: "SET_VALIDATION_ERRORS",
        payload: {
          errors: error.response.data,
        },
      };
    }
    if (error.response.status === 403) {
      return {
        type: "ALERT_ERROR",
        payload: {
          message: error.response.data.message,
        },
      };
    } else {
      return {
        type: "ALERT_ERROR",
        payload: {
          message: "Ein Fehler ist aufgetreten.",
        },
      };
    }
    /*console.log(error.response.headers);*/
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    return {
      type: "SET_GENERIC_ERRORS",
      payload: {
        errors: error.message,
      },
    };
  } else {
    return {
      type: "SET_GENERIC_ERRORS",
      payload: {
        errors: error.message,
      },
    };
  }
};
