import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Table
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {getAgendaItemTemplates} from "../../actions/agendaItemTemplateAction";
import {useDispatch, useSelector} from "react-redux";
import {importAgendaFromTemplate} from "../../actions/meetingAction";
import TextInput from "../Inputs/TextInput";

const AgendaItemTemplateImport = ({modal, toggle, handleClose,forceReload}) => {

    const {agenda_item_templates, agenda_item_template_waiting} = useSelector(state => state.agenda_item_templates);
    const {selected_meeting, isWaiting} = useSelector(state => state.meetings)
    const [selected_items, setSelectedItems] = useState({});
    const [filtered_agenda_item_templates, setFilteredAgendaItemtemplates] = useState(agenda_item_templates);
    const [submitted, setSubmitted] = useState(false)
    const [query, setQuery] = useState("");


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAgendaItemTemplates())
    }, [dispatch])


    useEffect(() => {
        if(!isWaiting && submitted) {
            setSubmitted(false)
            forceReload(true);
            toggle();
        }
// eslint-disable-next-line
    },[isWaiting])

    useEffect(() => {

        const timeoutId = setTimeout(() =>{
            let searched_templates = agenda_item_templates.filter(agenda_item_template => {
                return agenda_item_template.title.toLowerCase().includes(query.toLowerCase());
            })

            setFilteredAgendaItemtemplates(searched_templates);
        }, 500);
        return () => clearTimeout(timeoutId);



    },[query, agenda_item_templates])


        const handleSearchQuery = (e) => {
            const {value} = e.target
            setQuery(value);
        }

    const handleInputChange = (e) => {
        const {checked, value} = e.target;

        if(checked) {
            setSelectedItems(prevState => {
                return {...prevState, [value]: checked}
            });
        } else {
            if (value in selected_items) {
                setSelectedItems((prevState) => {
                    const {[value]: remove, ...rest} = prevState;
                    return rest;
                })
            }
        }
    }

    const handleSubmit = () => {
        dispatch(importAgendaFromTemplate({agenda_item_template_ids: Object.keys(selected_items), meeting_id:selected_meeting.id}));
        setSubmitted(true);
    }


    const renderAgendaItemTemplateList = () => {

        if (agenda_item_template_waiting && agenda_item_templates.length === 0) {
            return (
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            )
        }


        if (agenda_item_templates.length === 0 && !agenda_item_template_waiting) {
            return "Zurzeit sind keine TOP Templates angelegt"
        }


        return (
            <Table className="mb-0" responsive>
                <thead>
                <tr>
                    <th>Auswahl</th>
                    <th>Name</th>
                    <th>Abstimmungen</th>
                </tr>
                </thead>
                <tbody>
                {filtered_agenda_item_templates.map(agenda_item_template => {
                    return (
                        <tr key={agenda_item_template.id} className="weg-list">
                            <td>
                                <input
                                type="checkbox"
                                value={agenda_item_template.id}
                                onChange={handleInputChange}
                                checked={agenda_item_template.id in selected_items}
                                />
                            </td>

                            <td>
                                {agenda_item_template.title}
                            </td>
                            <td>
                                {agenda_item_template.voting_templates ? agenda_item_template.voting_templates.length : "0"}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>

        )

    }


    return (

        <Modal size="lg" isOpen={modal} backdrop="static" toggle={toggle} scrollable={true} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>TOP Template hinzufügen</ModalHeader>
            <ModalBody>
                <TextInput name="Test" placeholder="Suche..." handleInputChange={e => {
                    handleSearchQuery(e)
                }}/>
                {renderAgendaItemTemplateList()}
                <div className="processingButtons">
                    <Button color="lightgray" onClick={toggle}>Abbrechen</Button>
                    <Button onClick={handleSubmit} color="primary">Zuweisen</Button>
                </div>
            </ModalBody>
        </Modal>

    )


}

export default AgendaItemTemplateImport;