import React from "react";
import SingleVoteModal from "../SingleVoteModal";

export default function VoteForm({ votings, selectedParticipant, toggleVote }) {
  let active_voting = votings.filter(
    (item) => item.active === 1 && item.paused === 0
  );
  return (
    <SingleVoteModal
      voting={active_voting[0]}
      participant={selectedParticipant}
      toggle={toggleVote}
    />
  );
}
