import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken} from "../_services/userService";


export const getWegs = () => async (dispatch) => {
    dispatch({type:"WEG_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/weg`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(function (response) {
            dispatch({
                type: "FETCH_WEGS",
                payload: {
                    wegs: response.data.items
                }
            });
        }).catch(function (error) {
            dispatch(errorBuilder(error));
        });
};


export const deleteWeg = (id) => (dispatch) => {
    dispatch({type:"WEG_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/weg/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_WEG",
                payload: {
                    id: id
                }
            })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

}

export const addWeg = (wegData) => async (dispatch) => {
    dispatch({type:"WEG_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/weg`, {
        name: wegData.name,
        suffrage:wegData.suffrage,
        power_of_proxy: wegData.power_of_proxy
    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "ADD_WEG",
            payload: {
                weg: response.data.weg
            }
        })
    }).catch(error => {
        dispatch(errorBuilder(error));
    });
}


export const editWeg = (id, wegData) => async (dispatch) => {
    dispatch({type:"WEG_LOADING"})
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/weg/${id}`, {
        name: wegData.name,
        suffrage:wegData.suffrage,
        power_of_proxy: wegData.power_of_proxy

    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: "EDIT_WEG",
                    payload: {
                        weg: response.data.weg,
                        id: id
                    }
                })
            }

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

};


export const showWeg = (id) => async (dispatch) => {
    dispatch({type:"WEG_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/weg/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "SHOW_WEG",
                payload: {
                    weg: response.data.weg
                }
            })
        }).catch(error => {
            dispatch(errorBuilder(error));
        });
}