import wegReducer from "./wegReducer";
import { combineReducers } from "redux";
import meetingReducer from "./meetingReducer";
import agendaItemReducer from "./agendaItemReducer";
import participantReducer from "./participantReducer";
import { errorReducer } from "./errorReducer";
import votingReducer from "./votingReducer";
import userReducer from "./userReducer";
import resultReducer from "./resultReducer";
import voteReducer from "./voteReducer";
import { alertReducer } from "./alertReducer";
import organisationReducer from "./organisationReducer";
import agendaItemTemplateReducer from "./agendaItemTemplateReducer";
import votingTemplateReducer from "./votingTemplateReducer";

const rootReducer = combineReducers({
  wegs: wegReducer,
  meetings: meetingReducer,
  agenda_items: agendaItemReducer,
  participants: participantReducer,
  votings: votingReducer,
  errors: errorReducer,
  user: userReducer,
  result: resultReducer,
  vote: voteReducer,
  alert: alertReducer,
  organisation: organisationReducer,
  agenda_item_templates: agendaItemTemplateReducer,
  voting_templates: votingTemplateReducer,
});

export default rootReducer;
