import Emailnput from "../Inputs/EmailInput";

const PasswordForgot = ({email,setEmail,submit}) => {


    return (
        <form>
            <Emailnput
                placeholder="E-Mail-Adresse eingeben"
                name="email"
                label="E-Mail-Adresse"
                handleInputChange={(e) =>  setEmail(e.target.value)}
            />
            <button
                type="submit"
                className="btn btn-primary"
                onClick={submit}
            >Passwort Zurücksetzen
            </button>
        </form>
    )
}


export default PasswordForgot;