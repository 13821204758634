import {
    Modal,
    ModalBody,
    ModalHeader,
    Card,
    CardHeader,
    NavLink,
    UncontrolledCollapse,
} from "reactstrap";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Chart from "react-apexcharts";
import {Link} from "react-router-dom";

const Result = (props) => {
    const {
        result,
        voting,
        details,
        vote_count,
        not_voted_log,
        not_voted_count,
        final_result_identifier,
        not_voted_power_percent,
        voted_power_percent,
    } = useSelector((state) => state.result);
    const apexDonutOpts = {
        chart: {
            height: 320,
            type: "pie",
        },
        labels: [],
        colors: ["#08b973", "#ea324c", "#f2ac42", "#449bd3", "#aa65cc"],
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const apexDonutData = [];
    const dispatch = useDispatch();

    useEffect(() => {
        return function cleanup() {
            dispatch({type: "CLEAR_RESULT"});
        };
    }, [dispatch]);

    const renderChart = () => {
        if (result) {
            if (
                voting.type === "select" ||
                voting.type === "multi_select" ||
                voting.type === "read_and_confirm"
            ) {
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of result.entries()) {
                    apexDonutOpts.labels.push(value.label);
                    apexDonutData.push(value.votes);
                }
                if (apexDonutData.reduce(reducer) === 0) {
                    return "Bisher wurden keine Stimmen abgegeben.";
                }

                let text = "";
                if (voting.type === "select" || voting.type === "multi_select") {
                    if (final_result_identifier === 1) {
                        text = <strong>Der Antrag wurde angenommen.</strong>;
                    }

                    if (final_result_identifier === 2) {
                        text = <strong>Der Antrag wurde abgelehnt.</strong>;

                        if (voting.decision_key === "double_qualified")
                            text = (
                                <strong>Es wurde keine doppelte qualifizierte Mehrheit erreicht.<br/>
                                    Der Antrag wurde abgelehnt.</strong>
                            );
                    }

                    if (final_result_identifier === 3) {
                        text = (
                            <strong>
                                Es konnte kein Einstimmiges Ergebnis festgelegt werden.<br/>
                                Der Antrag wurde abgelehnt.</strong>
                        );
                    }

                    if (final_result_identifier === 4) {
                        if (voting.decision_key === "all") {
                            text = <strong>Es wurde keine Allstimmigkeit erreicht.<br/>
                                Der Antrag wurde abgelehnt.</strong>
                        } else {
                            text = (
                                <strong>Es wurde keine doppelte qualifizierte Mehrheit erreicht.<br/>
                                    Der Antrag wurde abgelehnt.
                                </strong>
                            );
                        }
                    }
                }
                return (
                    <>
                        {text !== "" ? <p className="chartResultBox">{text}</p> : null}
                        <Chart
                            options={apexDonutOpts}
                            series={apexDonutData}
                            type="pie"
                            height={500}
                            className="apex-charts"
                        />
                    </>
                );
            }

            if (voting.type === "text") {
                return (
                    <table className="table table-responsive resultTable">
                        <thead className="thNoBorder">
                        <th>Teilnehmer</th>
                        <th>Eingabe</th>
                        </thead>
                        <tbody>
                        {result.map((item) => {
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.value}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                );
            }
        } else {
            return "Bisher wurden keine Stimmen abgegeben.";
        }
    };

    const renderDetails = () => {
        if (result && details) {
            if (result.length > 0) {
                return (
                    <div className="resultHistory">
                        <h5>
                            Bereits abgestimmt <br/>
                            Teilnehmer: {vote_count}
                            <br/>
                            {voted_power_percent
                                ? "Nach Teiler: " + voted_power_percent + "%"
                                : null}
                        </h5>
                        <div>
                            <div id="accordion" className="custom-accordion mb-4 w-100">
                                {result.map((res, key) => {
                                    return (
                                        <Card className="mb-1 p-0">
                                            <CardHeader className="p-0">
                                                <h5 className="m-0">
                                                    <NavLink
                                                        className="custom-accordion-title d-block pt-2 pb-2"
                                                        id={`group${key}`}
                                                        href="#"
                                                    >
                                                        {res.label} Anzahl der Stimmen: {res.votes}
                                                        <span className="float-right">
                              <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                            </span>
                                                    </NavLink>
                                                </h5>
                                            </CardHeader>
                                            <UncontrolledCollapse toggler={`#group${key}`}>
                                                <div className="px-1 py-2">
                                                    {details.map((item) => {
                                                        if (item.label === res.label) {
                                                            return (
                                                                <p
                                                                    className={"mb-1 mr-1"}
                                                                    title={"Abgegebene Stimme: " + item.label}
                                                                >
                                                                    {item.name}
                                                                </p>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </UncontrolledCollapse>
                                        </Card>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            }
        }

        /*  if(details) {
                if (details.length > 0) {
                    return (
                        <div className="resultHistory">
                            <h5>Bereits abgestimmt  <br />(nach Köpfen: {vote_count} {voted_power_percent ? 'Nach Teiler: '+ voted_power_percent +'%' : null})</h5>
                            <div>
                                <div id="accordion" className="custom-accordion mb-4 w-100">
                                    <Card className="mb-0 p-0">
                                        <CardHeader className="p-0">
                                            <h5 className="m-0">
                                                <NavLink className="custom-accordion-title d-block pt-2 pb-2" id="group1" href="#">
                                                    Antwort A
                                                    <span className="float-right">
                                                        <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                                                    </span>
                                                </NavLink>
                                            </h5>
                                        </CardHeader>
                                        <UncontrolledCollapse toggler="#group1">
                                            <CardBody>
                                                {details.map(item => {
                                                    return <p title={'Abgegebene Stimme: ' + item.label}>{item.name}</p>
                                                })}
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>
                                    <Card className="mb-0 p-0">
                                        <CardHeader className="p-0">
                                            <h5 className="m-0">
                                                <NavLink className="custom-accordion-title d-block pt-2 pb-2" id="group2" href="#">
                                                    Antwort B
                                                    <span className="float-right">
                                                        <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                                                    </span>
                                                </NavLink>
                                            </h5>
                                        </CardHeader>
                                        <UncontrolledCollapse toggler="#group2">
                                            <CardBody>
                                                {details.map(item => {
                                                    return <p title={'Abgegebene Stimme: ' + item.label}>{item.name}</p>
                                                })}
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>
                                    <Card className="mb-0 p-0">
                                        <CardHeader className="p-0">
                                            <h5 className="m-0">
                                                <NavLink className="custom-accordion-title d-block pt-2 pb-2" id="group3" href="#">
                                                    Antwort C
                                                    <span className="float-right">
                                                        <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                                                    </span>
                                                </NavLink>
                                            </h5>
                                        </CardHeader>
                                        <UncontrolledCollapse toggler="#group3">
                                            <CardBody>
                                                {details.map(item => {
                                                    return <p title={'Abgegebene Stimme: ' + item.label}>{item.name}</p>
                                                })}
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>
                                </div>
                            </div>
                        </div>
                )
                }
            }*/
    };

    const renderFinalResult = () => {
        if (!voting) return null;
        if (!voting.result) return null;
        return (
            <div className="resultBox">
                <p>Endergebnis</p>
                <p>
                    Mit {voting.result.votes} Stimmen wurde für{" "}
                    {voting.result.voting_option.label} gestimmt.
                </p>
            </div>
        );
    };

    const renderNotVotedLog = () => {
        if (not_voted_log) {
            if (not_voted_log.length > 0) {
                return (
                    <div className="resultHistory">
                        <h5>
                            Noch nicht abgestimmt <br/>
                            Teilnehmer: {not_voted_count}
                            <br/>{" "}
                            {not_voted_power_percent
                                ? "Nach Teiler (inkl. nicht anwesende) " +
                                not_voted_power_percent +
                                "%"
                                : null}
                        </h5>
                        <div>
                            {not_voted_log.map((item) => {
                                return <p>{item.name}</p>;
                            })}
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="resultHistory">
                <h5>
                    Noch nicht abgestimmt <br/>
                    Teilnehmer: {not_voted_count} <br/>
                    {not_voted_power_percent
                        ? "Nach Teiler (inkl. nicht anwesende) " +
                        not_voted_power_percent +
                        "%"
                        : null}
                </h5>
                <div>Es sind keine weiteren Teilnehmer anwesend.</div>
            </div>
        );
    };

    const renderSubHeader = () => {
        if (voting.decision_key === "custom") {
            return (
                <h2>
                    Teiler: {voting.divisor} Beschlussschlüssel:{" "}
                    {voting.custom_decision_key} (Eigene Eingabe)
                </h2>
            );
        }

        if (voting.decision_key === "qualified") {
            return (
                <h2>
                    Teiler: {voting.divisor} Beschlussschlüssel: Qualifizierte Mehrheit
                </h2>
            );
        }

        if (voting.decision_key === "double_qualified") {
            return (
                <h2>
                    Teiler: {voting.divisor} Beschlussschlüssel: Doppelt Qualifizierte
                    Mehrheit
                </h2>
            );
        }

        if (voting.decision_key === "simple") {
            return (
                <h2>Teiler: {voting.divisor} Beschlussschlüssel: Einfache Mehrheit</h2>
            );
        }

        if (voting.decision_key === "all") {
            return (
                <h2>Teiler: {voting.divisor} Beschlussschlüssel: Allstimmigkeit</h2>
            );
        }
    };

    return (
        <Modal
            size={
                !vote_count
                    ? ""
                    : voting.type === "select" ||
                    voting.type === "multi_select" ||
                    voting.type === "read_and_confirm"
                        ? "xl"
                        : ""
            }
            onExit={props.handleClose}
            isOpen={props.modal}
            backdrop="static"
            toggle={props.toggle}
            className="modal-dialog-centered result-modal"
        >
            <ModalHeader toggle={props.toggle}>
                Ergebnis: {voting.title} <br/> <br/>
                TOP {voting.AgendaItem && voting.AgendaItem.order}:{" "}
                {voting.AgendaItem && voting.AgendaItem.title}
                <Link
                    title="In neuem Tab öffnen"
                    target="_blank"
                    to={`/result/${voting.id}`}
                    class="new-tab-link"
                >
                    <i className="mdi mdi-open-in-new"/>
                </Link>
                {renderSubHeader()}
            </ModalHeader>
            <ModalBody>
                <div className="result-diagram">
                    {renderFinalResult()}
                    {renderChart()}
                </div>
                {renderDetails()}
                {renderNotVotedLog()}
            </ModalBody>
        </Modal>
    );
};

export default Result;
