import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken} from "../_services/userService";

export const getResult = (id) => async(dispatch) => {
    dispatch({type:"RESULT_LOADING"});
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/result/${id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_RESULT",
                payload:{
                    result: response.data.result,
                    voting: response.data.voting,
                    details: response.data.detail_log,
                    not_voted_log: response.data.not_voted_log,
                    vote_count: response.data.vote_count,
                    not_voted_count: response.data.not_voted_count,
                    final_result_identifier: response.data.final_result_identifier,
                    not_voted_power_percent: response.data.not_voted_power_percent,
                    voted_power_percent: response.data.voted_power_percent
                }
            });

        }).catch(error => {
            dispatch(errorBuilder(error));
        });

};