import { useSelector } from "react-redux";

const DivisorInput = ({
  name,
  label,
  placeholder,
  value,
  handleInputChange,
  index,
}) => {
  const errors = useSelector((state) => state.errors);

  const renderClass = () => {
    if (Object.entries(errors.errors).length !== 0) {
      if (errors.errors["divisors"]) {
        return "form-control form-control is-invalid";
      }
    }
    return "form-control";
  };

  const renderFeedback = () => {
    if (Object.entries(errors.errors).length !== 0) {
      if (errors.errors["divisors"]) {
        const keyElemet = errors.errors["divisors"];
        return (
          <div className="invalid-feedback">
            {Object.entries(keyElemet).map(([key, value]) => {
              return value.map((val) => {
                return (
                  <p>
                    {val.code}: {val.message}
                  </p>
                );
              });
            })}
          </div>
        );
      }
    }
  };

  const renderLabel = () => {
    if (label) {
      return <label>{label}</label>;
    }
  };

  return (
    <div className="form-group">
      {renderLabel()}
      <input
        type="text"
        placeholder={placeholder}
        className={renderClass()}
        aria-invalid="true"
        name={name}
        onChange={(e) => handleInputChange(e, index)}
      />

      {renderFeedback()}
    </div>
  );
};

export default DivisorInput;
