import {Button, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import React from "react";
import TextInput from "../Inputs/TextInput";

const AddWeg = (props) => {
    return (
        <Modal onExit={props.onClosed} isOpen={props.modal} backdrop="static" toggle={props.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={props.toggle}>WEG erstellen</ModalHeader>
            <ModalBody>
                <Form onSubmit={props.handleSubmit}>
                    <TextInput name="name" errors={props.errors} handleInputChange={props.handleInputChange} value={props.inputValue.name} label="Name der WEG*" placeholder="Name eingeben" />
                    <TextInput name="power_of_proxy" errors={props.errors} handleInputChange={props.handleInputChange} value={props.inputValue.power_of_proxy} label="Vollmachtserteilung erlaubt an" placeholder="Vollmachtserteilung erlaubt an eingeben" />
                    <TextInput name="suffrage" errors={props.errors} handleInputChange={props.handleInputChange} value={props.inputValue.suffrage} label="Stimmrecht nach" placeholder="Stimmrecht nach eingeben" />
                    <div className="processingButtons">
                        <Button color="lightgray" onClick={props.toggle}>Abbrechen</Button>
                        <Button color="primary" >Erstellen</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default AddWeg;