const initState = {
  wegs: [],
  selected_weg: {
    name: "",
  },
  isWaiting: false,
};

const wegReducer = (state = initState, action) => {
  switch (action.type) {
    case "FETCH_WEGS":
      return { ...state, wegs: action.payload.wegs, isWaiting: false };
    case "DELETE_WEG":
      const filterdWeg = state.wegs.filter(
        (item) => item.id !== action.payload.id
      );
      return { ...state, wegs: filterdWeg, isWaiting: false };
    case "ADD_WEG":
      return {
        ...state,
        wegs: state.wegs.concat(action.payload.weg),
        isWaiting: false,
      };
    case "WEG_LOADING":
      return {
        ...state,
        isWaiting: true,
      };
    case "WEG_LOADING_ABORT":
      return {
        ...state,
        isWaiting: false,
      };
    case "EDIT_WEG":
      const newState = state.wegs.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload.weg;
        }
        return item;
      });
      return { ...state, wegs: newState, isWaiting: false };
    case "SHOW_WEG":
      return { ...state, selected_weg: action.payload.weg, isWaiting: false };
    case "CLEAR_WEGS":
      return initState;
    default:
      return { ...state };
  }
};

export default wegReducer;
