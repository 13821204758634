import React, {useState} from "react";
import {Button} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {stopVoting, startVoting, pauseVoting, unpauseVoting, deleteVoting} from "../actions/votingAction";
import {getResult} from "../actions/resultAction";
import ReadMoreReact from "read-more-react";
import "../assets/scss/slideOut.scss";
import EditVoting from "./Forms/EditVoting";

const Voting = ({
  meeting,
  voting,
  toggelShowResult,
  quorate,
  toggleDeputyVote,
  isVotingActive,
  deputy_vote_enabled,
}) => {
  const dispatch = useDispatch();
  const { selected_meeting } = useSelector((state) => state.meetings);
  const { deputys } = useSelector((state) => state.user);
  const [edit_voting, setEditVoting] = useState(false);

  const startButtonHandler = () => {
    if (!meeting.meeting_start) {
      alert(
        "Versammlung muss gestarted werden, bevor eine Abstimmung gestarted werden kann"
      );
    } else {
      if (isVotingActive) {
        if (
          window.confirm(
            "Eine andere Abstimmung ist gerade aktiv, durch das starten einer neuen Abstimmung wird diese automatisch gestoppt. Wollen Sie die neue Abstimmung wirklich starten?"
          )
        ) {
          dispatch({ type: "ALERT_CLEAR" });
          dispatch(startVoting(voting.id));
        }
      } else {
        dispatch({ type: "ALERT_CLEAR" });
        dispatch(startVoting(voting.id));
      }
    }
  };

  const stopButtonHandler = () => {
    dispatch({ type: "ALERT_CLEAR" });
    dispatch(stopVoting(voting.id));
  };

  const pauseButtonHandler = () => {
    dispatch({ type: "ALERT_CLEAR" });
    dispatch(pauseVoting(voting.id));
  };

  const unpauseButtonHandler = () => {
    dispatch({ type: "ALERT_CLEAR" });
    dispatch(unpauseVoting(voting.id));
  };

  const deleteButtonHandler = () => {
    dispatch({ type: "ALERT_CLEAR" });
    dispatch(deleteVoting(voting.id));
  };

  const renderResultButton = () => {
    return (
      <Button onClick={() => resultButtonHandler()} color="info">
        Ergebnisse anzeigen
      </Button>
    );
  };

  const resultButtonHandler = () => {
    dispatch(getResult(voting.id));
    toggelShowResult();
  };

  const renderDeleteButton = () => {
    if (selected_meeting.closed) {
      return "";
    }
    if (voting.active) {
      return (
        <Button disabled={true} color="danger">
          Abstimmung Löschen
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            if (
              window.confirm(
                "Sind Sie sicher, dass sie das Voting löschen wollen?"
              )
            )
              deleteButtonHandler();
          }}
          color="danger"
        >
          Löschen
        </Button>
      );
    }
  };

  const setDeputyVote = () => {
    dispatch({ type: "SELECT_VOTING", payload: { voting: voting } });
    toggleDeputyVote();
  };

  const renderDeputyVoteButton = () => {
    if (selected_meeting.closed) {
      return null;
    }

    // if (!deputy_vote_enabled && deputys.length <= 0) return null;

    return (
      <Button color="info" onClick={() => setDeputyVote()}>
        Als Vertreter abstimmen
      </Button>
    );
  };

  const renderPlayButtons = () => {
    if (!quorate) {
      return <div className="votingPlayButton">Nicht beschlussfähig</div>;
    }

    if (selected_meeting.closed) {
      return (
        <div className="votingPlayButton">
          Die Versammlung wurde geschlossen.
        </div>
      );
    }

    if (!voting.stopped) {
      if (voting.active) {
        if (voting.paused) {
          return (
            <div className="votingPlayButtons">
              <span
                onClick={() => startButtonHandler()}
                disabled={true}
                className="disabled"
              >
                <i className="mdi mdi-play"></i>
              </span>
              <span onClick={() => unpauseButtonHandler()}>
                <i className="mdi mdi-pause paused"></i>
              </span>
              <span
                onClick={() => {
                  if (
                    window.confirm(
                      "Sind Sie sicher, dass sie das Voting stoppen wollen? Dieses kann nicht erneut gestartet werden."
                    )
                  )
                    stopButtonHandler();
                }}
              >
                <i className="mdi mdi-stop"></i>
              </span>
              <span className="votingStatus">Pausiert</span>
            </div>
          );
        }
        if (!voting.paused) {
          return (
            <div className="votingPlayButtons">
              <span
                onClick={() => startButtonHandler()}
                disabled={true}
                className="disabled"
              >
                <i className="mdi mdi-play"></i>
              </span>
              <span onClick={() => pauseButtonHandler()}>
                <i className="mdi mdi-pause"></i>
              </span>
              <span
                onClick={() => {
                  if (
                    window.confirm(
                      "Sind Sie sicher, dass sie das Voting stoppen wollen? Dieses kann nicht erneut gestartet werden."
                    )
                  )
                    stopButtonHandler();
                }}
              >
                <i className="mdi mdi-stop"></i>
              </span>
              <span className="votingStatus">Aktiv</span>
            </div>
          );
        }
      }
      if (!voting.active) {
        return (
          <div className="votingPlayButtons">
            <span onClick={() => startButtonHandler()}>
              <i className="mdi mdi-play"></i>
            </span>
            <span
              onClick={() => unpauseButtonHandler()}
              disabled={true}
              className="disabled"
            >
              <i className="mdi mdi-pause"></i>
            </span>
            <span
              onClick={() => stopButtonHandler()}
              disabled={true}
              className="disabled"
            >
              <i className="mdi mdi-stop"></i>
            </span>
            <span className="votingStatus">Nicht aktiv</span>
          </div>
        );
      }
    } else {
      return (
        <div className="votingDone">
          <i className="dripicons-checkmark" />
          Abstimmung geschlossen
        </div>
      );
    }
  };

  const renderModal = () => {
    if (!edit_voting) return null;

    return (
      <EditVoting
        modal={edit_voting}
        toggle={() => setEditVoting(!edit_voting)}
        voting={voting}
      />
    );
  };

  return (
    <>
      <div
        className={
          deputys.length === 0
            ? "votingRow"
            : "votingRow gridTemplateWithDeputy"
        }
      >
        <h5>
          {voting.is_motion ? (
            <div className="d-block">
              <strong>Beschlussantrag:</strong>{" "}
              <i>(Art der Abstimmung: {voting.type})</i>
            </div>
          ) : (
            <div className="d-block">
              <strong>{voting?.title}:</strong>{" "}
              <i>(Art der Abstimmung: {voting.type})</i>
            </div>
          )}
          {voting.description ? (
            <ReadMoreReact
              key={voting.description}
              text={voting.description}
              min={1}
              ideal={300}
              max={400}
              readMoreText={"Vollständigen Text anzeigen"}
            />
          ) : (
            <i>Keine Beschreibung angegeben</i>
          )}
        </h5>
        {renderPlayButtons()}
        <div className="additionalButtons">
          {renderDeputyVoteButton()}
          {renderResultButton()}
          <button
            className={"btn btn-info"}
            onClick={() => setEditVoting(!edit_voting)}
          >
            Bearbeiten
          </button>
          {renderDeleteButton()}
        </div>
      </div>
      {renderModal()}
      <hr />
    </>
  );
};

export default Voting;