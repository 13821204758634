import { Button, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import React from "react";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import DateInput from "../Inputs/DateInput";
import TimeInput from "../Inputs/TimeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "../../assets/scss/tooltip.scss";

export default function AddMeeting(props) {
  if (!props.modal) {
    return null;
  }

  return (
    <Modal
      size="lg"
      onExit={props.onClosed}
      isOpen={props.modal}
      toggle={props.toggle}
      backdrop="static"
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={props.toggle}>Versammlung erstellen</ModalHeader>
      <ModalBody>
        <Form onSubmit={props.handleSubmit}>
          <Row>
            <Col>
              <p className="d-inline-block mb-1">Versammlungsname*</p>
              <TextInput
                name="title"
                value={props.values.title}
                label={false}
                placeholder="Versammlungsname eingeben"
                errors={props.errors}
                handleInputChange={props.handleInputChange}
              />
            </Col>
            <Col>
              <p className="d-inline-block mb-1">Versammlungsart*</p>
              <FontAwesomeIcon
                data-tip
                data-for="type"
                className="ml-1 custtooltip-trigger"
                icon={faInfoCircle}
              />
              <ReactTooltip
                className="custtooltip"
                id="type"
                place="top"
                effect="solid"
              >
                Hier kann die Art der Versammlung gewählt werden.
              </ReactTooltip>
              <div className="no-label">
                <SelectInput
                  name="meeting_type"
                  value={props.values.meeting_type}
                  label={false}
                  placeholder="Versammlungsart eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                  options={["Ordentlich", "Außerordentlich"]}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="d-inline-block mb-1">Datum der Einladung*</p>
              <div className="no-label">
                <DateInput
                  name="invitation_date"
                  value={props.values.invitation_date}
                  label={false}
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="d-inline-block mb-1">Datum der Versammlung*</p>
              <div className="no-label">
                <DateInput
                  name="date"
                  value={props.values.date}
                  label={false}
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </div>
            </Col>
            <Col>
              <p className="d-inline-block mb-1">Uhrzeit der Versammlung*</p>
              <div className="no-label">
                <TimeInput
                  name="time"
                  value={props.values.time}
                  label={false}
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <p className="d-inline-block mb-1">
                Protokollführung/Versammlungsleitung*
              </p>
              <TextInput
                name="meeting_leader"
                value={props.values.meeting_leader}
                label={false}
                placeholder="Protokollführung/Versammlungsleitung angeben"
                errors={props.errors}
                handleInputChange={props.handleInputChange}
              />
            </Col>
            <Col>
              <p className="d-inline-block mb-1">Versammlungsort*</p>
              <div className="no-label">
                <TextInput
                  name="place"
                  value={props.values.place}
                  label="Ort*"
                  placeholder="Ort eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="d-inline-block mb-1">Von der Verwaltung anwesend</p>
              <FontAwesomeIcon
                data-tip
                data-for="participants"
                className="ml-1 custtooltip-trigger"
                icon={faInfoCircle}
              />
              <ReactTooltip
                className="custtooltip"
                id="participants"
                place="top"
                effect="solid"
              >
                Hier können die von der Verwaltung anwesenden Personen
                eingetragen werden.
              </ReactTooltip>
              <TextInput
                name="administration"
                value={props.values.administration}
                label={false}
                placeholder="Verwaltung eingeben"
                errors={props.errors}
                handleInputChange={props.handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="d-inline-block mb-1">Teiler</p>
              <FontAwesomeIcon
                data-tip
                data-for="divisors"
                className="ml-1 custtooltip-trigger"
                icon={faInfoCircle}
              />
              <ReactTooltip
                className="custtooltip"
                id="divisors"
                place="top"
                effect="solid"
              >
                Hier werden die Titel der Teiler aus der
                Teil&shy;nehmer&shy;liste (z.B. Excel aus dem Export Ihres
                Haus&shy;verwaltungs&shy;programms) angegeben. Achten Sie auf
                die genaue Übernahme der Be&shy;zeich&shy;nung. Die Aufzählung
                mehrerer Teiler werden via Komma getrennt.
              </ReactTooltip>
              <TextInput
                name="divisors"
                value={props.values.divisors}
                label={false}
                placeholder="Teiler 1, Teiler 2"
                errors={props.errors}
                handleInputChange={props.handleInputChange}
              />
            </Col>
          </Row>

          {/* TODO check backend to see where to divide  */}
          {/* <Row>
            <Col>
              <p className="d-inline-block mb-1">Teiler NUMMER</p>
              <FontAwesomeIcon
                data-tip
                data-for="divider_number"
                className="ml-1 custtooltip-trigger"
                icon={faInfoCircle}
              />
              <ReactTooltip
                className="custtooltip"
                id="divider_number"
                place="top"
                effect="solid"
              >
                Hier kann die Teilernummer festgelegt werden
              </ReactTooltip>
              <div className="no-label">
                <SelectInput
                  name="divider_number"
                  value={props.values.divider_number}
                  label={false}
                  placeholder="Teiler eingeben"
                  errors={props.errors}
                  handleInputChange={props.handleInputChange}
                  options={["1.000", "10.000"]}
                />
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <p className="d-inline-block mb-1">Bemerkung (Intern)</p>
              <FontAwesomeIcon
                data-tip
                data-for="comment"
                className="ml-1 custtooltip-trigger"
                icon={faInfoCircle}
              />
              <ReactTooltip
                className="custtooltip"
                id="comment"
                place="top"
                effect="solid"
              >
                Hier können Bemerkungen für den internen Gebrauch eingetragen
                werden.
              </ReactTooltip>
              <TextAreaInput
                name="comment"
                value={props.values.comment}
                label={false}
                placeholder="Bemerkung eingeben"
                errors={props.errors}
                handleInputChange={props.handleInputChange}
              />
            </Col>
          </Row>
          <div className="processingButtons">
            <Button color="lightgray" onClick={props.toggle}>
              Abbrechen
            </Button>
            <Button color="primary">Erstellen</Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
